import React from "react";
import Modal from "react-modal";
import "../style/resturant.css";
import { useTranslation } from "react-i18next";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    borderRadius: 30,
    marginRight: "-50%",

    transform: "translate(-50%, -50%)",
  },
};
function Resturant() {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const { t } = useTranslation();

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div>
      <div class="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500  flex  flex-col h-screen justify-center items-center  ">
        <div className=" text-[6rem] md:text-[7rem]  font-bold text-white ">
          linkakam
        </div>
        <div className="text-[2rem] text-white">
          {t("for restaurant services")}
        </div>
        <button
          onClick={openModal}
          className="text-[#8e5bf5] bg-gray-200 text-2xl  px-10 mt-8 rounded-3xl"
        >
          {t("Contact Us")}
        </button>

        <img
          src={`${process.env.PUBLIC_URL}/images/f1.png`}
          className="   w-32 absolute md:left-72 left-56 top-24 object-cover h-32 animateY"
          alt=""
        />
        <img
          src={`${process.env.PUBLIC_URL}/images/f2.png`}
          className="  hidden md:block  md:w-40 absolute left-12 top-44 object-cover h-40 animateY"
          alt=""
        />
        <img
          src={`${process.env.PUBLIC_URL}/images/f3.png`}
          className=" w-20 md:w-44 absolute top-20 left-4 md:top-20 md:left-1/2  object-cover md:h-44 h-20 animateY2 "
          alt=""
        />
        <img
          src={`${process.env.PUBLIC_URL}/images/f4.png`}
          className=" w-28 md:w-24 absolute left-2/3 md:top-96 top-[700px] object-cover h-24  	 animateY2"
          alt=""
        />
        <img
          src={`${process.env.PUBLIC_URL}/images/f5.png`}
          className="  w-24 absolute left-20  top-[680px] md:left-72  md:top-3/4 object-cover h-24 animateY"
          alt=""
        />
        <img
          src={`${process.env.PUBLIC_URL}/images/f6.png`}
          className="   w-40 md:w-44 absolute top-44   left-1/3 md:top-20 md:left-2/3  object-cover md:h-44 h-40 animateY2"
          alt=""
        />
        <img
          src={`${process.env.PUBLIC_URL}/images/f7.png`}
          className="hidden md:block w-24 absolute top-[500px] object-cover h-24 animateY"
          alt=""
        />
      </div>

      {/* //Services */}
      <div class="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 flex w-full   flex-row h-60 justify-center items-center  ">
        <div className=" w-1/2 h-60 ml-12 md:mx-32  ">
          <h1 className="text-[42px] md:text-[64px] text-white ">
            {" "}
            {t("Services")}{" "}
          </h1>
          <div className="w-60">
            <p className="  text-xl text-white">&#x2022; {t("Barcode menu")}</p>
            <p className="  text-xl text-white">&#x2022; {t("Branding")} </p>
            <p className="  text-xl text-white">&#x2022; {t("Menu design")} </p>
            <p className="  text-xl text-white">
              &#x2022; {t("Restaurant Detail Showcase")}
            </p>
            <p className="  text-xl text-white">
              &#x2022;
              {t("Social Media Management")}
            </p>
          </div>
        </div>
        <div className="w-1/2 h-60">
          <img
            src={`${process.env.PUBLIC_URL}/images/pizza.png`}
            className="w-96 mt-16 md:mt-0 object-cover  animateY"
            alt=""
          />
        </div>
      </div>

      {/* Trusted By */}
      <div class=" bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 md:h-96   flex w-full   flex-row   justify-center items-center  ">
        <div className="mt-16      text-center  w-full ">
          <h1 className="text-[42px]   md:text-[64px] text-white ">
            {t("Trusted By")}
          </h1>
          <div className="flex     flex-col md:flex-row     pt-10">
            <div
              className="w-5/6 mx-auto my-4 py-4   bg-gray-200  rounded-3xl text-center  md:w-1/3   md:mx-20  md:h-72 md:p-6 
            "
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/5qoli.png`}
                className=" w-5/6  mx-auto my-4  object-cover  animateY"
                alt=""
              />
              <p className="text-xl bold">{t("5 qoli fastfood")}</p>
              <p className="pb-4"> {t("sulimany")}</p>
              <a
                href="#"
                className="bg-[#8e5bf5] -mx-4  text-white px-8 md:px-8 mt-2 text-xl  rounded-3xl"
              >
                {t("see more")}
              </a>
            </div>
            <div
              className="w-5/6 mx-auto my-4 py-4   bg-gray-200  rounded-3xl text-center  md:w-1/3   md:mx-20  md:h-72 md:p-6 
            "
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/5qoli.png`}
                className=" w-5/6  mx-auto my-4  object-cover  animateY"
                alt=""
              />
              <p className="text-xl bold">{t("5 qoli fastfood")}</p>
              <p className="pb-4"> {t("sulimany")}</p>
              <a
                href="#"
                className="bg-[#8e5bf5] -mx-4  text-white px-8 md:px-8 mt-2 text-xl  rounded-3xl"
              >
                {t("see more")}
              </a>
            </div>
            <div
              className="w-5/6 mx-auto my-4 py-4   bg-gray-200  rounded-3xl text-center  md:w-1/3   md:mx-20  md:h-72 md:p-6 
            "
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/5qoli.png`}
                className=" w-5/6  mx-auto my-4  object-cover  animateY"
                alt=""
              />
              <p className="text-xl bold">{t("5 qoli fastfood")}</p>
              <p className="pb-4"> {t("sulimany")}</p>
              <a
                href="#"
                className="bg-[#8e5bf5] -mx-4  text-white px-8 md:px-8 mt-2 text-xl  rounded-3xl"
              >
                {t("see more")}
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* data */}
      <div class=" bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500   flex w-full  pt-2 px-2 flex-row h-[500px] md:h-[600px] justify-center    ">
        <div className="md:mt-12 w-full md:w-1/2 py-12 h-60 md:mx-32 ml-0">
          <h1 className="text-[42px] md:text-[64px] text-white ">
            {" "}
            {t("Contactless Menus")}
          </h1>
          <div className="w-full ">
            <p className=" text-xl text-white my-5">
              {t(
                "Our QR code menus automatically sync to your printed in-house menus! Change one, they all change. Customers get fast, easy access to your menu with a quick scan on their phones.  Easy-to-read layouts are optimized for speed and legibility on smart phones. Includes automatic navigation so your customers can skip to their favorite menu sections instantaneously."
              )}
            </p>
            <button
              onClick={openModal}
              className="text-[#8e5bf5] bg-gray-200 text-2xl  px-10 mt-2 rounded-3xl"
            >
              {t("Contact Us")}
            </button>
          </div>
        </div>
        <div className="hidden md:block w-1/2 md:mt-16 h-60">
          <img
            src={`${process.env.PUBLIC_URL}/images/qr.png`}
            className="hidden md:block w-96 object-cover md:mt-12 animateY"
            alt=""
          />
        </div>
      </div>

      {/* contact us */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="modares">
          <div
            style={{
              alignItems: "center",
              width: "100%",
            }}
          >
            <img src={`${process.env.PUBLIC_URL}/images/black.png`} alt="" />
            <div
              style={{
                width: "100%",

                textAlign: "center",
              }}
              className="grid grid-cols-2  gap-8   "
            >
              <div>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.facebook.com/"
                >
                  <i className="fab fa-facebook text-4xl text-blue-600"></i>
                  <p>Facebook</p>
                </a>
              </div>
              <div>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.instagram.com/"
                >
                  <i className="fab fa-instagram  text-4xl text-pink-600"></i>
                  <p>instagram</p>
                </a>
              </div>
              <div>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="mailto:support@linkakam.com?subject=resturant%20package"
                >
                  <i class="fa-solid fa-envelope text-4xl text-red-400"></i>

                  <p>support@linkakam.com</p>
                </a>
              </div>
              <div>
                <a href="tel:+9647737330003">
                  <i class="fa-solid fa-phone  text-4xl text-green-400"></i>
                  <p>07737330003</p>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="text-right ">
          <button
            style={{
              width: 100,
              height: 30,
              borderRadius: 10,
              color: "white",
              marginTop: 40,
              backgroundColor: "#2a5ce6",
            }}
            onClick={closeModal}
          >
            {t("close")}
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default Resturant;
