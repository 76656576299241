import QRCode from "qrcode";
import { useState } from "react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

function Qrcode(url2) {
  
  const [qr, setQr] = useState("");
  const { t } = useTranslation();

  const GenerateQRCode = () => {
    if (url2?.url2 || false) {
      console.log(url2.url2);
      QRCode.toDataURL(
        url2.url2,
        {
          width: 800,
          margin: 2,
          color: {
            dark: "#243d62",
            light: "#f3f3f3",
          },
        },
        (err, url) => {
          if (err) {
            return console.error(err);
          }
          console.log(url);
          setQr(url);
        }
      );
    }
  };
  useEffect(() => {
    GenerateQRCode();
  });
  return (
    <div className="qrcon">
      {qr && (
        <>
          <img className="" alt="linkakam" src={qr} />
          <div className="linkcon">
            <a href={qr} download="qrcode.png">
               {t("Download")}
            </a>
          </div>
        </>
      )}
    </div>
  );
}
export default Qrcode;
