import axios from "axios";

if (localStorage.getItem("token")) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${
    JSON.parse(localStorage.getItem("token")).token
  }`;

  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Content-Type"] = "application/json";
}

export default axios.create({
  baseURL: `https://api.linkakam.com/api`,
});
