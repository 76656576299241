import React, { useState, useEffect } from "react";
import "../style/setup.css";
import Show from "./Show";
import Login from "./Login";
import axios from "../axios";
import Modal from "react-modal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactLoading from "react-loading";
import Qr from "../componant/Qr";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import imageCompression from "browser-image-compression";

function setToken(userToken) {
  localStorage.setItem("token", JSON.stringify(userToken));
}
const customStyles = {
  content: {
    borderRadius: 20,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const customStyles2 = {
  content: {
    border: "none",

    borderRadius: "20px",
    padding: "40px 60px",

    boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)",

    top: "55%",
    left: "50%",
    right: "auto",
    bottom: "0",
    with: "55%",

    height: "60%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
function getToken() {
  const tokenString = localStorage.getItem("token");
  const userToken = JSON.parse(tokenString);
  return userToken?.token;
}

const Setup = () => {
  const { t } = useTranslation();
  let formData = new FormData();
  const [imgchange, setimgchange] = useState(false);
  const [imgSet, setimgSet] = useState(false);
  const [bio, setbio] = useState();
  const [picture, setpicture] = useState(" ");
  const [showpicture, setshowpicture] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpen2, setIsOpen2] = useState(false);

  const [copy, setcopy] = useState(false);
  const [load, setload] = useState(true);
  const [havepic, sethavepic] = useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  function openModal2(event) {
    event.preventDefault();
    setIsOpen2(true);
  }
  function closeModal2() {
    setIsOpen2(false);
  }
  const [id, setid] = useState();

  const [inputFields, setInputFields] = useState([
    { social_name: "", image: "", link: "" },
  ]);

  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;

    setInputFields(data);
  };
  function afterOpenModal() { }
  function afterOpenModal2() { }
  const remove = (index) => {
    inputFields.splice(index, 1);
    setInputFields([...inputFields]);
  };
  const addFields = () => {
    let newfield = { social_name: "", image: "", link: "" };

    setInputFields([...inputFields, newfield]);
  };
  let u = JSON.parse(localStorage.getItem("token"));

  const submit = (e) => {
    e.preventDefault();
    let a = cheackfornull();
    if (a === 2) {
      formData.append("bio", bio);
      if (picture?.size || false) {
        formData.append("avatar", picture);
      }

      console.log(u.user.username);

      formData.append("username", u.user.username);

      axios
        .post("/update?_method=put", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          window.location.reload();
        })
        .catch(() => { });
      axios
        .post("/user/link", inputFields)
        .then(() => { })
        .catch(() => { });
    } else {
      Swal.fire({
        icon: "error",
        title: "make sure you fill all the fields",
        text: "",
      });
    }
  };
  const cheackfornull = () => {
    console.log(inputFields);

    for (let index = 0; index < inputFields.length; index++) {
      const element = inputFields[index];
      console.log(element.social_name);
      console.log(element.link);
      console.log(element.image);
      if (element.social_name === "") {
        return 1;
      }
      if (element.link === "") {
        return 1;
      }
      if (element.image === "") {
        return 1;
      }
    }
    return 2;
  };
  const getdata = () => {
    let ary = [];
    axios
      .get("/user/link")
      .then(({ data }) => {
        data.links.forEach((element) => {
          let newfield = {
            social_name: element.social_name,
            image: element.icon,
            link: element.link,
          };
          ary.push(newfield);
        });
        console.log(ary.length);
        if (ary.length > 0) {
          setInputFields(ary);
        }
      })
      .catch(() => { });

    axios
      .get("/get/user")
      .then(({ data }) => {
        console.log(data);
        console.log(data.user.bio);
        if (data.user.bio !== "null") {
          setbio(data.user.bio);
        }
        if (data.user.image !== null) {
          console.log("its null");
          sethavepic(true);
        }

        setpicture(data.user.image);

        setid(data.user.username);
        setTimeout(() => {
          setload(false);
        }, 10);
      })

      .catch(() => { });
  };

  useEffect(() => {
    getdata();
    console.log(picture);
  }, []);

  const token = getToken();
  if (!token) {
    return <Login setToken={setToken} />;
  }
  function handleRemoveImage() {
    setpicture(`${process.env.PUBLIC_URL}/images/pp.png`);

  }


  async function handleImageUpload(event) {
    let size = event.target.files[0].size / 1049 / 1000;
    console.log(size + "in mb");
    if (size < 2) {
      setimgchange(true);

      setpicture(event.target.files[0]);
      setshowpicture(URL.createObjectURL(event.target.files[0]));
    } else {
      const imageFile = event.target.files[0];
      console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
      console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

      const options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);
        console.log(
          "compressedFile instanceof Blob",
          compressedFile instanceof Blob
        ); // true
        console.log(
          `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
        ); // smaller than maxSizeMB

        setimgchange(true);
        setimgSet(true);
        setpicture(compressedFile);
        setshowpicture(URL.createObjectURL(event.target.files[0]));
      } catch (error) {
        console.log(error);
      }
    }
  }
  return (
    <div className="back bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 flex flex-col h-full min-h-screen pt-8  items-center flex-wrap">
      {load ? (
        <div style={{ margin: "auto" }}>
          <ReactLoading
            type={"spin"}
            color={"#2a5ce6"}
            height={60}
            width={60}
          />
        </div>
      ) : (
        <>
          <div class="bg-white w-[50%] rounded-2xl shadow-md hidden md:block ">
            <div className="setcol1">
              <form onSubmit={submit} method="post" className="">
                <div className="w-full flex-col text-center  items-center  ">
                  <div className="w-full  first-letter mt-0 rounded-t-lg h-[] z-10  justify-center items-center ">
                    <img
                      className="relative w-full rounded-t-2xl"
                      alt="linkakam"
                      src={`${process.env.PUBLIC_URL}/images/coverr.png`}
                    /><div className="relative buttom-0  -mt-28 "  >

                      <label className="items-center">
                        <>
                          <input
                            style={{ display: "none" }}
                            type="file"
                            accept="image/*"
                            onChange={(event) => {
                              handleImageUpload(event);
                            }} />
                          {!imgchange ? (
                            havepic ? (
                              <img
                                className="imgsetup  z-50 "
                                alt="linkakam"
                                src={"https://api.linkakam.com/profile/" + picture}
                              />
                            ) : (
                              <img
                                className="imgsetup"
                                alt="linkakam"
                                src={`${process.env.PUBLIC_URL}/images/pp.png`}
                              />
                            )
                          ) : (<>
                            <img className="imgsetup  " alt="linkakam" src={showpicture} />
                          </>
                          )}
                        </>

                      </label>
                    </div>
                    <div className=" m-[20px]">
                      <button className="relative text-black-500  font-bold  text-center " onClick={openModal2}>Preview
                        <img
                          className="ml-3"
                          alt="linkakam"

                          src={`${process.env.PUBLIC_URL}/images/icons8-eye.gif`} ture png
                        />
                      </button>
                    </div>
                  </div>

                  <div className="setcol1 mt-12 xl:mt-2 mb-4 ">
                    <input
                      required
                      placeholder={t("bio")}
                      className="bio"
                      value={bio}
                      onChange={(event) => setbio(event.target.value)}
                    ></input>
                  </div>

                </div>
                {inputFields.map((input, index) => {
                  return (
                    <div key={index} className="dform ">
                      <div className="dformcon items-center">
                        <input
                          required
                          placeholder={t("link titel")}
                          className="social_name"
                          name="social_name"
                          value={input.social_name}
                          onChange={(event) => handleFormChange(index, event)}
                        ></input>
                        <select
                          name="image"
                          id="icon"
                          value={input.image}
                          onChange={(event) => handleFormChange(index, event)}
                        >
                          <option value=""> {t("select")}</option>

                          <option value="fa-brands fa-facebook  text-blue-600">
                            Facebook
                          </option>
                          <option value="fab fa-instagram text-pink-600">
                            Instagram
                          </option>
                          <option value="fab fa-tiktok text-black">Tiktok</option>
                          <option value="fa-brands fa-square-youtube text-red-600">
                            Youtube
                          </option>

                          <option value="fa-brands fa-snapchat text-yellow-500">
                            Snapchat
                          </option>
                          <option value="fa-solid fa-globe">Website</option>
                          <option value="fab fa-linkedin text-blue-600">
                            Linkedin
                          </option>

                          <option value="fa-brands fa-telegram text-blue-400">
                            Telegram
                          </option>
                          <option value="fab fa-whatsapp text-green-400">
                            whatsapp
                          </option>

                          <option value="fab fa-twitter text-blue-400">
                            Twitter
                          </option>
                          <option value="fab fa-viber text-purple-400">
                            Viber
                          </option>
                          <option value="tell fa-solid fa-phone text-green-400">
                            Call
                          </option>
                          <option value="fab fa-behance text-black">Behance</option>
                          <option value="fab fa-github text-black">Github</option>
                          <option value="fa-solid fa-link text-black">Other</option>
                        </select>

                        <input
                          required
                          name="link"
                          placeholder={t("link")}
                          onChange={(event) => handleFormChange(index, event)}
                          value={input.link}
                        ></input>
                        <div className="delbtn">
                          <button
                            type="button"
                            onClick={() => {
                              remove(index);
                            }}
                          >
                            <i className="fa-solid fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="subadd  mb-6 flex mr-4 justify-center items-center  justify-items-center">
                  <div>
                    <button type="button" onClick={addFields} style={{ backgroundColor: "#2d2d2e" }}>
                      {t("Add")}
                    </button>
                  </div>
                  <div>
                    <button type="submit" onClick={submit}>
                      {t("Submit")}
                    </button>
                  </div>
                  <div className="overflow-y-scroll flex justify-center ">
               
              </div>
                </div>
              </form> 
              <div className="flex justify-center">
                  <button onClick={openModal} className="sharebtnDesk">
                    {t("")} <i className="fa-solid fa-share"></i>
                  </button>
                </div>
              
            </div>
          </div>



          {/* mobile */}

          <div class="bg-white -mt-8 shadow-md  lg:hidden ">
            <div className="setcol1">


              <form onSubmit={submit} method="post" className="">
                <div className="w-full flex-col text-center  items-center  ">
                  <div className="w-full  first-letter mt-0 rounded-t-lg h-[] z-10  justify-center items-center ">
                    <img
                      className="relative w-full rounded-t-2xl"
                      alt="linkakam"
                      src={`${process.env.PUBLIC_URL}/images/coverr.png`}
                    /><div className="relative buttom-0  -mt-16 "  >

                      <label className="items-center pt">
                        <>
                          <input
                            style={{ display: "none" }}
                            type="file"
                            accept="image/*"
                            onChange={(event) => {
                              handleImageUpload(event);
                            }} />
                          {!imgchange ? (
                            havepic ? (
                              <img
                                className="imgsetup3  "
                                alt="linkakam"
                                src={"https://api.linkakam.com/profile/" + picture}
                              />
                            ) : (
                              <img
                                className="imgsetup3"
                                alt="linkakam"
                                src={`${process.env.PUBLIC_URL}/images/pp.png`}
                              />
                            )
                          ) : (<>
                            <img className="imgsetup3  " alt="linkakam" src={showpicture} />
                          </>
                          )}
                        </>

                      </label>
                    </div>
                    <div className=" m-[20px]">
                      <button className="relative text-black-500  font-bold  text-center " onClick={openModal2}>Preview
                        <img
                          className="ml-3"
                          alt="linkakam"

                          src={`${process.env.PUBLIC_URL}/images/icons8-eye.gif`} ture png
                        />
                      </button>

                    </div>
                  </div>

                  <div className="  flex justify-center justify-items-center text-center  xl:mt-2 mb-4 ">
                    <input
                      required
                      placeholder={t("bio")}
                      className="bio text-center "
                      name="social_name"
                      value={bio}
                      onChange={(event) => setbio(event.target.value)}
                    ></input>
                  </div>

                </div>
                {inputFields.map((input, index) => {
                  return (
                    <div key={index} className="dform ">
                      <div className="dformcon items-center">
                        <input
                          required
                          placeholder={t("link titel")}
                          className="social_name"
                          name="social_name"
                          value={input.social_name}
                          onChange={(event) => handleFormChange(index, event)}
                        ></input>
                        <select
                          name="image"
                          id="icon"
                          value={input.image}
                          onChange={(event) => handleFormChange(index, event)}
                        >
                          <option value=""> {t("select")}</option>

                          <option value="fa-brands fa-facebook  text-blue-600">
                            Facebook
                          </option>
                          <option value="fab fa-instagram text-pink-600">
                            Instagram
                          </option>
                          <option value="fab fa-tiktok text-black">Tiktok</option>
                          <option value="fa-brands fa-square-youtube text-red-600">
                            Youtube
                          </option>

                          <option value="fa-brands fa-snapchat text-yellow-500">
                            Snapchat
                          </option>
                          <option value="fa-solid fa-globe">Website</option>
                          <option value="fab fa-linkedin text-blue-600">
                            Linkedin
                          </option>

                          <option value="fa-brands fa-telegram text-blue-400">
                            Telegram
                          </option>
                          <option value="fab fa-whatsapp text-green-400">
                            whatsapp
                          </option>

                          <option value="fab fa-twitter text-blue-400">
                            Twitter
                          </option>
                          <option value="fab fa-viber text-purple-400">
                            Viber
                          </option>
                          <option value="fab fa-call text-purple-400">
                            call
                          </option>
                          <option value="fab fa-behance text-black">Behance</option>
                          <option value="fab fa-github text-black">Github</option>
                          <option value="fa-solid fa-link text-black">Other</option>
                        </select>

                        <input
                          required
                          name="link"
                          placeholder={t("link")}
                          onChange={(event) => handleFormChange(index, event)}
                          value={input.link}
                        ></input>
                        <div className="delbtn ">
                          <button
                            type="button"
                            onClick={() => {
                              remove(index);
                            }}
                          >
                            <i className="fa-solid fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="subadd  mb-6 flex mr-4 justify-center items-center  justify-items-center">
                  <div>
                    <button type="button" onClick={addFields} style={{ backgroundColor: "#2d2d2e" }}>
                      {t("Add")}
                    </button>
                  </div>
                  <div>
                    <button type="submit" onClick={submit}>
                      {t("Submit")}
                    </button>
                  </div>

                </div>


              </form>
              <div className="overflow-y-scroll flex justify-center mt-1">
                <div className=" block lg:hidden">
                  <button onClick={openModal} className="sharebtn">
                    {t("")} <i className="fa-solid fa-share"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {load ? (
        <p></p>
      ) : (
        <>
          {/* Mobile */}
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div className="modalcon">
              <div
                style={{
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {u?.user || false ? (
                  <Qr
                    url2={"https://linkakam.com/" + u.user.username.toString()}
                  />
                ) : (
                  ""
                )}
              </div>
              <div
                style={{
                  width: "100%",
                  padding: "20px 0",
                }}
              >
                <CopyToClipboard text={"linkakam.com/" + u.user.username}>
                  <div
                    style={{
                      height: 30,
                      margin: "auto 0",
                      width: "100%",

                      border: "1px solid gray",
                      borderRadius: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0 15px",
                    }}
                  >
                    <button
                      onClick={() => {
                        setcopy(true);
                      }}
                    >
                      linkakam.com/{u.user.username}{" "}
                    </button>
                    <p> {!copy ? t("copy") : t("copied")}</p>
                  </div>
                </CopyToClipboard>

                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    padding: 40,
                    textAlign: "center",
                  }}
                  className="grid grid-cols-3 gap-8"
                >
                  <div>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.facebook.com/"
                    >
                      <i className="fab fa-facebook text-4xl text-blue-600"></i>
                    </a>
                  </div>
                  <div>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.instagram.com/"
                    >
                      <i className="fab fa-instagram  text-4xl text-pink-600"></i>
                    </a>
                  </div>
                  <div>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.tiktok.com/en/"
                    >
                      <i className="fab fa-tiktok  text-4xl"></i>
                    </a>
                  </div>
                  <div>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.snapchat.com/"
                    >
                      <i className="fab fa-youtube  text-4xl text-red-600 "></i>
                    </a>
                  </div>
                  <div>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.linkedin.com/"
                    >
                      <i className="fab fa-linkedin  text-4xl text-blue-400"></i>
                    </a>
                  </div>
                  <div>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.snapchat.com/"
                    >
                      <i className="fab fa-snapchat  text-4xl text-yellow-600"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-right ">
              <button
                style={{
                  width: 100,
                  height: 30,
                  borderRadius: 10,
                  color: "white",
                  backgroundColor: "#2a5ce6",
                }}
                onClick={closeModal}
              >
                {t("close")}
              </button>
              <Qr />
            </div>
          </Modal>

          {/* Desktop */}
          <div className="setcol3 hidden sm:block ">

            <Modal
              isOpen={modalIsOpen}
              onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className="modalcon">
                <div
                  style={{
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {u?.user || false ? (
                    <Qr
                      url2={"https://linkakam.com/" + u.user.username.toString()}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div
                  style={{
                    width: "100%",
                    padding: "20px 0",
                  }}
                >
                  <CopyToClipboard text={"linkakam.com/" + u.user.username}>
                    <div
                      style={{
                        height: 30,
                        margin: "auto 0",
                        width: "100%",

                        border: "1px solid gray",
                        borderRadius: 10,
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "0 15px",
                      }}
                    >
                      <button
                        onClick={() => {
                          setcopy(true);
                        }}
                      >
                        linkakam.com/{u.user.username}{" "}
                      </button>
                      <p> {!copy ? t("copy") : t("copied")}</p>
                    </div>
                  </CopyToClipboard>

                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      padding: 40,
                      textAlign: "center",
                    }}
                    className="grid grid-cols-3 gap-8"
                  >
                    <div>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.facebook.com/"
                      >
                        <i className="fab fa-facebook text-4xl text-blue-600"></i>
                      </a>
                    </div>
                    <div>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.instagram.com/"
                      >
                        <i className="fab fa-instagram  text-4xl text-pink-600"></i>
                      </a>
                    </div>
                    <div>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.tiktok.com/en/"
                      >
                        <i className="fab fa-tiktok  text-4xl"></i>
                      </a>
                    </div>
                    <div>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.snapchat.com/"
                      >
                        <i className="fab fa-youtube  text-4xl text-red-600 "></i>
                      </a>
                    </div>
                    <div>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.linkedin.com/"
                      >
                        <i className="fab fa-linkedin  text-4xl text-blue-400"></i>
                      </a>
                    </div>
                    <div>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.snapchat.com/"
                      >
                        <i className="fab fa-snapchat  text-4xl text-yellow-600"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-right ">
                <button
                  style={{
                    width: 100,
                    height: 30,
                    borderRadius: 10,
                    color: "white",
                    backgroundColor: "#2a5ce6",
                  }}
                  onClick={closeModal}
                >
                  {t("close")}
                </button>
                <Qr />
              </div>
            </Modal>
            <Modal
              isOpen={modalIsOpen2}
              onAfterOpen={afterOpenModal2}
              onRequestClose={closeModal2}
              style={customStyles2}
              className={""}
              contentLabel="Example Modal"
            >
              {/* Close button with absolute positioning */}
              <button
                style={{
                  position: 'absolute',
                  top: '1rem',
                  right: '0.5rem',
                  width: '30px',
                  height: '30px',
                  borderRadius: '15px',
                  fontWeight: 'bold',
                  color: 'white',
                  backgroundColor: '#cc2424',
                  zIndex: 1000, // High z-index to ensure it's on top
                }}
                onClick={closeModal2}
              >
                X
              </button>

              {/* Mobile device container */}
              <div className="mobile-device-container" style={{
                position: 'relative',
                width: '260px', // typical width of a mobile device
                height: '475px',
                maxHeight: "auto", // typical height of a mobile device
                overflow: 'hidden',
                marginBottom: '1rem',
                borderRadius: '35px',
                right: '0',
                left: '0',
                boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',
                border: '4px solid black',

              }}>
                <div className="content" style={{
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: '0',
                  margin: '0',
                  overflowY: 'scroll',
                }}>
                  <Show id={id} />
                </div>
              </div>
            </Modal>


          </div>
        </>
      )}
    </div>
  );
};

export default Setup;
