import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../axios";
import ReactLoading from "react-loading";
const Show = (props) => {
  let { user } = useParams();
  const [profile, setProfile] = useState();
  const [load, setload] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    let uid = user
      ? user
      : props.id
        ? props.id
        : JSON.parse(localStorage.getItem("token")).user.username;
    axios
      .get("/user/" + uid)
      .then(({ data }) => {
        setProfile(data.data);
        setload(false);
      })
      .catch(() => {
        navigate("/en/home");
      });
  }, [props.id]);
  const checkIfKurdish = (val) => {
    let kurdish = /[\u0600-\u06FF]/;
    return kurdish.test(val);
  };

  //
  return (
    <>
      {load ? (
        <div className="min-h-screen flex z-50 flex-wrap">
          <div style={{ margin: "auto" }}>
            <ReactLoading
              type={"spin"}
              color={"#2a5ce6"}
              height={60}
              width={60}
            />
          </div>
        </div>
      ) : !props.id ? (
        <>
          {/* phone*/}
          <div className="md:hidden bg-white " >
            <div className="h-96 relative">
              <div className="h-40 w-full absolute bottom-0 left-0 right-0  ">
                <p className="text-3xl absolute bottom-14 font-bold text-black/70 ">
                  @{profile?.username}
                </p>
              </div>
              {profile?.image ? (
                <img
                  src={`https://api.linkakam.com/profile/${profile?.image}`}
                  className="h-full  -z-10 object-cover w-full"
                  alt=""
                />
              ) : (
                <img
                  src={`${process.env.PUBLIC_URL}/images/df.png`}
                  alt=""
                  className="home__hero-imgREB"
                />
              )}
            </div>
            <div className="bg-white">
              <div className="  z-50 rounded-tr-3xl relative -top-10 rounded-tl-3xl">
                <p
                  className={
                    (checkIfKurdish(profile?.bio) ? "text-right" : "") +
                    " text-white p-4 pb-6 text-lg "
                  }
                >
                  {profile?.bio !== "null" && profile?.bio !== "undefined"
                    ? profile?.bio
                    : " "}
                </p>
              </div>
              <div className="h-40  bg-[white]  z-50 rounded-tr-3xl relative -top-14 rounded-tl-3xl">
                <div className="w-10/12 mx-auto py-5 space-y-4">
                  {profile?.links.map((element, index) => {
                    return (
                      <a
                        href={
                          element.link.slice(0, 4) === "http"
                            ? element.link
                            : "http://" + element.link

                        }
                        className="text-sm bg-white shadow-lg rounded-3xl flex px-3  py-[5px] items-center justify-between text-black  "
                      >
                        <div key={index} className="text-sm">
                          {element.social_name}
                          {/* {element.link.slice(0, 4)} */}
                        </div>
                        <i className={element.icon + " text-xl"}></i>
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          {/* laptop screen */}
          <div className="hidden md:block ">
            <div className="md:flex">
              <div className="h-96  md:h-screen md:basis-4/12 relative">
                <div className="h-40 w-full absolute bottom-0 left-0 bg-gradient-to-t from-[white]   to-transparent">
                  <p className="text-3xl mx-3 absolute bottom-14 font-bold text-black/70 mt-32">
                    @{profile?.username}
                  </p>
                </div>
                {profile?.image ? (
                  <img
                    src={`https://api.linkakam.com/profile/${profile?.image}`}
                    className="h-full bg-[white] -z-10 object-cover w-full"
                    alt=""
                  />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/images/df.png`}
                    alt=""
                    className="home__hero-imgREB"
                  />
                )}
              </div>
              <div className="basis-8/12   bg-[white]  -left-5 rounded-tl-3xl overflow-hidden rounded-bl-3xl relative ">
                <div className="absolute rounded-3xl  top-0 z-50 w-full">
                  <div className=" border-l-2 border-blue-600  top-0 z-50 w-11/12  mx-auto m-2">
                    <p
                      className={
                        (checkIfKurdish(profile?.bio) ? "text-right" : "") +
                        "text-gray-600 p-4 pb-6 text-lg"
                      }
                    >
                      {profile?.bio !== "null" && profile?.bio !== "undefined"
                        ? profile?.bio
                        : " "}
                    </p>
                  </div>
                </div>
                <div className=" min-h-screen pt-20 px-4 overflow-hidden bg-[white] rounded-tr-3xl relative rounded-tl-3xl md:rounded-none">
                  <div className="w-full mx-auto py-5 grid grid-cols-1 md:grid-cols-4 gap-10">
                    {profile?.links.map((element, index) => {
                      return (
                        <a
                          href={
                            element.link.slice(0, 4) === "http"
                              ? element.link
                              : "http://" + element.link
                          }
                          className=""
                        >
                          <div key={index} className="aspect-square p-6">
                            <div className="py-1 shadow-xl w-full h-full rounded-xl text-center pt-6 text-[#2a5ce6] hover:text-gray-700">
                              <p>
                                <i
                                  className={element.icon + " text-5xl mb-2"}
                                ></i>
                              </p>
                              <a href={element.link} className="">
                                {element.social_name}
                              </a>
                            </div>
                          </div>
                        </a>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        //setup
        <div
          className=""
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/mobile.png)`,
            backgroundRepeat: 'repeat-y',  // This will make the image repeat vertically
            backgroundSize: 'cover',  // This ensures the image covers the width of the div
            backgroundPosition: 'center',  // This centers the image in the div
            height: '100%',  // Adjust as needed
            width: '100%',
            right: '0',
        }}
        
        >
          <div className="h-40 pt-4 relative z-30">
            <div className="h-40 w-full absolute bottom-0 right-0 left-0">
              <p className="mx-3  text-center  font-bold text-black/70 mt-24">
                @{profile?.username}
              </p>
            </div>
            {profile?.image ? (
              <img

                src={`https://api.linkakam.com/profile/${profile?.image}`}
                className="imgsetup2"
                style={{ border: '4px solid black' }}
                alt=""
              />
            ) : (
              <img
                src={`${process.env.PUBLIC_URL}/images/df.png`}
                alt=""
                className="home__hero-imgREB"
              />
            )}
          </div>
          <div className=" z-0 text-center relative -top-14 ">
            <p
              className={
                (checkIfKurdish(profile?.bio) ? "text-right" : "") +
                " text-black  p-4 pb-6 text-sm "
              }
            >
              {profile?.bio !== "null" && profile?.bio !== "undefined"
                ? profile?.bio
                : " "}
            </p>
          </div>
          <div className=" z-0  relative -top-20 ">
            <div className="px-1 mx-auto py-5 space-y-2">
              {profile?.links.map((element, index) => {
                return (



                  <a
                    href={!element.icon.slice(0, 4) == "tell" ?
                      (element.link.slice(0, 4) === "http"
                        ? element.link
                        : "http://" + element.link) : (element.link.slice(0, 4) === "http"
                          ? element.link
                          : "tell:"+element.link )
                    }
                    className="text-xs bg-[#ffffff] shadow-lg  rounded-3xl mx-4 my-2  flex px-6 py-[6px] items-center justify-between text-black "
                  >

                    <div key={index} className="text-xs">
                      {element.social_name}
                    </div>
                    <i className={element.icon + " text-xl"}></i>
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Show;
