import "./App.css";
import "./i18nextConf";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Navbar from "./componant/Navbar";
import Home from "./page/Home";
import Login from "./page/Login";
import Setup from "./page/Setup";
import Show from "./page/Show";
import Cv from "./page/Cv";
import GetCv from "./page/getCv";
import Hire from "./page/hire";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import ReactGA from "react-ga";
import Resturant from "./page/Resturant";

function App({ props }) {
  const location = useLocation().pathname;
  const [dir, setdir] = useState("ltr");

  useEffect(() => {
    console.log("runnnnnn");
    ReactGA.initialize("UA-239858558-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    console.log(localStorage.getItem("lang") === "en" ? "ltr" : "rtl");
    setdir(localStorage.getItem("lang") === "en" ? "ltr" : "rtl");
  }, [dir]);
  return (
    <div className="font-face-gm" dir={dir}>
      {location.startsWith("/en") ? <Navbar /> : <></>}
      <Routes>
        <Route path="/en/home" element={<Home />}></Route>
        <Route path="/en/Login" element={<Login />}></Route>
        <Route path="/en/Setup" element={<Setup />}></Route>
        <Route path="/en/Show" element={<Show />}></Route>
        <Route path="/en/Cv" element={<Cv />}></Route>
        <Route path="/en/cv/getCv" element={<GetCv />} />
        <Route path="/en/cv/hire" element={<Hire />} />
        <Route path="/en/Resturants" element={<Resturant />}></Route>
        <Route path="/:user" element={<Show />}></Route>
        <Route path="*" element={<Navigate replace to="/en/home" />} />
      </Routes>
    </div>
  );
}

export default App;
