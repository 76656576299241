export const TRANSLATIONS_KU = {
  "uploud image": "دانانی وێنە",
  bio: "دەربارە",
  "link titel": "ناوی لینک",
  select: "هەڵبژێرە",
  link: "لینک",
  share: "بڵاوکردنەوە",
  copy: "کۆپی",
  copied: "کۆپی کرا",
  close: "داخستن",
  Submit: "تۆمارکردن",
  Add: "زیاد کردنی لینک",
  Download: "داگرتن",
  Setup: "ئامادە کردن",
  Show: "پیشاندان",
  Home: "ماڵەوە",
  setup: "ئامادە کردن",
  show: "پیشاندان",
  home: "ماڵەوە",
  Setup: "ئامادە کردن",

  Cv: "سیڤی",
  logout: "چوونە دەرەوە",
  "Coming Soon...": "...بەمزووانە",
  "The All in-One Link in Bio": "وێبسایتێک بۆ کۆکردنەوەی سەرجەم ",
  "for Creators, Brands, & Influencers": "سۆشیاڵ میدیاکان لە یەک لینکدا",
  "Drive Traffic from Instagram, Twitter or":
    "لەهەمان کاتدا دروست کردنی سیڤی و کیو ئاڕ کۆدی تایبەت بەخۆت.",
  "Facebook With Just One URL": "",
  "Search For Profile": "گەڕان بەدوای هەژماردا",
  Search: "گەڕان",
  "How it works": "چۆن کاردەکات",
  "Add all your links": "هەموو لینکەکانت زیاد بکە",
  "Connect your socials, website, store, videos, music, podcast, events and more. It all comes together in a link in bio landing page designed to convert.":
    "سۆشیالەکان، ماڵپەڕ، فرۆشگا، ڤیدیۆ، مۆسیقا، پۆدکاست، ڕووداوەکان و زۆر شتی ترت بەیەکەوە ببەستە. هەمووی لە بەستەرێکدا لە بایۆ .",
  "Help your followers": "یارمەتی فۆڵۆوەرەکانت بدە",
  "Help your followers discover everything you’re sharing all over the internet, in one simple place. They’ll thank you for it!":
    "یارمەتی فۆڵۆوەرەکانت بدە بۆ دۆزینەوەی هەموو ئەو شتانەی کە لە سەرانسەری ئینتەرنێتدا هاوبەشی دەکەیت، لە یەک شوێنی سادەدا. ئەوان سوپاسگوزار دەبن",
  "Share it everywhere!": "لە هەموو شوێنێک بڵاوی بکەرەوە",
  "Add your unique Linkakam URL to all the platforms and places  you find your audience. Then use your QR code to drive your offline traffic online.":
    "بەستەری ی ناوازەی لینکەکەم زیاد بکە بۆ هەموو ئەو پلاتفۆرم و شوێنانەی کە بینەرەکەت دەدۆزیتەوە. پاشان کۆدی کیو ئارکۆدەکەت  بەکاربهێنە بۆ ئەوەی ترافیکی ئۆفلاین بناسێنی بە هەژمارەکانت.",
  "Multiple links in one place": "چەندین بەستەر لە یەک شوێن",
  "our bio link tool helps promote your online presence, connect with your audience, and turn your bio into a landing page for your most important link":
    "لینکەکەم یارماتیت دەدات بەهێزتر دەربکەوی ئاسانکاریەکی تەواو دەبێ لە گەشتن بە بە هەژمار و لینکەکانت",
  "Get Your Free Link Now": "ئێستا لینکی خۆت بەدەست بێنە",
  email: "ئیمێڵ",
  register: "دروست کردن",
  login: "چونە ژوورەوە",
  "User Name": "ناو",
  password: "وشەی نهێنی",
  "Don't have an account?": "",
  "forget password": "بیرچوونەوەی وشەی نهێنی",
  username: "ناو",
  Password: "وشەی نهێنی",
  "Please Log In": "تکایە خۆت تۆمار بکە",
  "Register Please": "تکایە هەژمار دروست بکە",
  "Already have an account?": "خۆت هەژمارت هەیە؟",
  "enter your email and u will recive reset password link":
    "ئیمێڵەکەت تۆمار بکە بۆ وەرگرتنەوەی وشەی نهێنی",

  "for restaurant services": "بۆ خزمەتگوزاری چێشتخانە",
  "Contact Us": "پەیوەندی بکە",
  "Barcode menu": "باڕکۆد بۆ مینیو",
  Branding: "براندینگ",
  "Menu design": "دیزاینی مینیو",
  "Restaurant Detail Showcase": "پشاندانی زانیاری چێشتخانەکەت",
  "Social Media Management": "بەڕێوبردنی سۆشیاڵ میدیا",
  "Trusted By": "بەکارهێنەرەکان",
  "see more": "زیاتر ببینە",
  sulimany: "سلەیمانی",
  "5 qoli fastfood": "خواردەمەنی ٥ قۆڵی",
  "Our QR code menus automatically sync to your printed in-house menus! Change one, they all change. Customers get fast, easy access to your menu with a quick scan on their phones.  Easy-to-read layouts are optimized for speed and legibility on smart phones. Includes automatic navigation so your customers can skip to their favorite menu sections instantaneously.":
    "مینیوە QR کۆدەکەی ئێمە بە شێوەیەکی ئۆتۆماتیکی هاوشێوە دەبێ لەگەڵ مینیوە بەردەست و چاپکراوەکەت!  کڕیاران بە سکانێکی خێرا لە مۆبایلەکانیاندا بە خێرایی و بە ئاسانی دەستیان بە مینیوەکەت دەگات. شێوەیەکی سادە و ئاسانی دەبێت بۆ خێرایی و خوێندنەوە لەسەر مۆبایلە زیرەکەکان. بەش بەش کردن و ڕیزبەندی هەموو خواردنەکان و زانیاری و ناونیشانی چێشتخانە لە ناو لینکێک کۆبکەرەوە .",
  Services: "خزمەتگوزاریەکان",
  "Contactless Menus": "باڕکۆد بۆ مینیو",
  Resturants: "چێشتخانەکان",
};
