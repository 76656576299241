export const TRANSLATIONS_AR = {
  "uploud image": "تحميل الصورة",
  bio: "السيرة الذاتية",

  "link titel": "عنوان الارتباط",
  Select: "تحديد",
  link: "رابط",
  share: "شارك",
  copy: "نسخ",
  copied: "تم نسخه",
  close: "أغلق",
  Submit: "حفظ",
  Add: "إضافة",
  Download: "تحميل",
  Setup: "ئامادە کردن",
  Show: "عرض",
  Home: "الصفحة الرئيسية",
  setup: "اعداد",
  show: "عرض ",
  home: "الصفحة الرئيسية",
  logout: "تسجيل خروج",
  Cv: "السيرة الذاتية",
  "Coming Soon...": "قريبا...",
  "The All in-One Link in Bio":
    " موقع لجمع وسائل التواصل الاجتماعي في رابط واحد",
  "for Creators, Brands, & Influencers":
    "للمبدعين والعلامات التجارية والمؤثرين",
  "Drive Traffic from Instagram, Twitter or":
    "وفي الوقت نفسه يمكنك أنشاء (السيفي وكيو ئار كود) الخاص بك.",
  "Facebook With Just One URL": "",
  "Search For Profile": "البحث عن الملف الشخصي",
  Search: "بحث",
  "How it works": "كيف تعمل",
  "Add all your links": "أضف كل الروابط الخاصة بك",
  "Connect your socials, website, store, videos, music, podcast, events and more. It all comes together in a link in bio landing page designed to convert.":
    "قم بتوصيل مواقع التواصل الاجتماعي الخاصة بك وموقع الويب والمتجر ومقاطع الفيديو والموسيقى والبودكاست والأحداث والمزيد. كل ذلك يأتي معًا في رابط واحد",
  "Help your followers": "ساعد متابعيك",
  "Help your followers discover everything you’re sharing all over the internet, in one simple place. They’ll thank you for it!":
    "ساعد متابعيك في اكتشاف كل ما تشاركه عبر الإنترنت ، في مكان واحد بسيط. سوف يشكرونك على ذلك!",
  "Share it everywhere!": "شاركه في كل مكان!",
  "Add your unique Linkakam URL to all the platforms and places  you find your audience. Then use your QR code to drive your offline traffic online.":
    "أضف رابطك الفريد لجميع المنصات والأماكن التي تجد فيها جمهورك.",
  "Multiple links in one place": "روابط متعددة في مكان واحد",
  "our bio link tool helps promote your online presence, connect with your audience, and turn your bio into a landing page for your most important link":
    "تساعد أداة الارتباط الخاصة بنا في تعزيز تواجدك عبر الإنترنت ، والتواصل مع جمهورك ، وتحويل سيرتك الذاتية إلى صفحة مقصودة للرابط الأكثر أهمية.",
  "Get Your Free Link Now": "احصل على رابطك المجاني الآن",
  email: "البريد الإلكتروني",
  register: "تسجيل",
  login: "تسجيل الدخول",
  "User Name": " اسم االمستخدم",
  password: "كلمه السر",
  "Don't have an account?": "ليس لديك حساب؟",
  "forget password": "نسيت كلمة المرور",
  username: "اسم االمستخدم",
  Password: "كلمه السر",
  "Please Log In": "الرجاء تسجيل الدخول",
  "Register Please": "سجل من فضلك",
  "Already have an account?": "هل لديك حساب؟",
  "enter your email and you will recive reset password link":
    "أدخل بريدك الإلكتروني وستتلقى رابط إعادة تعيين كلمة المرور",

  "for restaurant services": "لخدمات المطاعم",
  "Contact Us": "اتصل بنا",
  "Barcode menu": "قائمة طعام الباركود",
  Branding: "العلامات التجارية والتسويق",
  "Menu design": "تصميم قائمة الطعام",
  "Restaurant Detail Showcase": "عرض تفاصيل المطعم",
  "Social Media Management": "إدارة وسائل التواصل الاجتماعي",
  "Trusted By": "المستخدمين",
  "see more": "شاهد المزيد",
  sulimany: "سلیمانیه",
  "5 qoli fastfood": "5 qoli fastfood",
  "Our QR code menus automatically sync to your printed in-house menus! Change one, they all change. Customers get fast, easy access to your menu with a quick scan on their phones.  Easy-to-read layouts are optimized for speed and legibility on smart phones. Includes automatic navigation so your customers can skip to their favorite menu sections instantaneously.":
    "تتم مزامنة قوائم رمز الاستجابة السريعة الخاصة بنا تلقائيًا مع القوائم المطبوعة! تغيير واحد ، كلهم يتغيرون. يحصل العملاء على وصول سريع وسهل إلى قائمتك من خلال المسح السريع على هواتفهم. تم تحسين التخطيطات سهلة القراءة من أجل السرعة والوضوح على الهواتف الذكية.",
  Services: "خزمەتگوزاریەکان",
  "Contactless Menus": "قوائم بدون تلامس",

  Resturants: "مطاعم",
};
