import React, { useState } from "react";
import axios from "../axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import "../style/home.css";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();

  const [search, setSearch] = useState("");
  const [profiles, setProfiles] = useState([]);

  const load = () => {
    axios.get(`/search/${search}`).then(({ data }) => {
      setProfiles(data.profiles);
      if (data.profiles.length === 0) {
        Swal.fire("Notfound");
      }
    });
  };

  return (
    <>
      {profiles.length === 0 ? (
        <>
          <div className="    md:w-full  p-2 md:h-screen  h-[700px]  relative ">
            <img
              alt=""
              src={`${process.env.PUBLIC_URL}/images/11.png`}
              className="absolute  md:w-6/12 object-cover  bottom-0 left-0 -z-10 w-full   md:block"
            />
            <div className="">
              <p className="text-xl md:text-3xl  text-center mt-14 text-gray-600">
                {t("The All in-One Link in Bio")}
                <br />
                {t("for Creators, Brands, & Influencers")}
              </p>
              <p className="text-xs md:text-sm mt-6 text-center text-gray-400">
                {t("Drive Traffic from Instagram, Twitter or")}

                <br />
                {t("Facebook With Just One URL")}
              </p>
            </div>
            <div className="md:w-5/12 mx-auto mt-14">
              <div className="border bg-white bg-opacity-90 border-blue-500 rounded-full overflow-hidden h-10  flex justify-between items-center">
                <input
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  placeholder={t("Search For Profile")}
                  type="text"
                  className="focus:outline-none px-2 py-2 bg-transparent pl-8"
                />
                <button
                  onClick={() => load()}
                  className="text-sm bg-blue-500 md:p-2 h-full  rounded-full text-white px-8 basis-4/12"
                >
                  {t("Search")}
                </button>
              </div>
              <div className="flex justify-center mt-12">
                <button
                  onClick={() => {
                    window.location.href = "/en/setup";
                  }}
                  className="text-sm bg-[#d7277d] md:p-2 font-bold h-8 md:h-full w-64 rounded-full text-white px-4 "
                >
                  {t("Get Your Free Link Now")}
                </button>
              </div>
              <div class="container">
                <div class="chevron"></div>
                <div class="chevron"></div>
                <div class="chevron"></div>
              </div>
            </div>
          </div>

          <div className="bg-[#d3defe] overflow-hidden p-5 flex space-x-3 items-center justify-between w-full">
            <i className="shadow-lg fab fa-facebook text-blue-600 rounded-2xl bg-white text-xl md:text-5xl py-2 md:py-3 px-3"></i>
            <i className="shadow-lg fab fa-instagram text-pink-600 rounded-2xl bg-white text-xl md:text-5xl py-2 md:py-3 px-3"></i>
            <i className="shadow-lg fab fa-tiktok text-black rounded-2xl bg-white text-xl md:text-5xl py-2 md:py-3 px-3"></i>
            <i className="shadow-lg fab fa-youtube text-red-600 rounded-2xl bg-white text-xl md:text-5xl py-2 md:py-3 px-3"></i>
            <i className="shadow-lg fab fa-snapchat text-yellow-600 rounded-2xl bg-white text-xl md:text-5xl py-2 md:py-3 px-3"></i>
            <i className="shadow-lg  hidden md:block fab fa-linkedin text-blue-600 rounded-2xl bg-white text-5xl py-3 px-4"></i>
            <i className="shadow-lg hidden md:block fab fa-twitter text-blue-400 rounded-2xl bg-white text-5xl py-3 px-4"></i>
            <i className="shadow-lg hidden md:block fab fa-whatsapp text-green-400 rounded-2xl bg-white text-5xl py-3 px-4"></i>
            <i className="shadow-lg hidden md:block fab fa-viber text-purple-400 rounded-2xl bg-white text-5xl py-3 px-4"></i>
            <i className="shadow-lg hidden md:block fab fa-behance text-black rounded-2xl bg-white text-5xl py-3 px-4"></i>
            <i className="shadow-lg hidden md:block fab fa-github text-black rounded-2xl bg-white text-5xl py-3 px-4"></i>
            <i className="shadow-lg hidden md:block fab fa-spotify text-green-500 rounded-2xl bg-white text-5xl py-3 px-4"></i>
          </div>
          <div className="w-9/12 flex items-center  mx-auto p-2 my-10 min-h-screen">
            <div>
              <p className="text-center text-4xl text-gray-500">
                {" "}
                {t("How it works")}
              </p>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-10 mt-10">
                <div>
                  {/* <img
                    src="https://cdn.wlo.link/static/landing/v2/wlo-add-all-your-links.png"
                    alt=""
                  /> */}
                  <img
                    alt=""
                    className="h-64"
                    src={`${process.env.PUBLIC_URL}/website.svg`}
                  />
                  <p className="text-2xl  text-gray-700 my-5">
                    {t("Add all your links")}
                  </p>
                  <p className="text-gray-500 text-sm mt-4">
                    {t(
                      "Connect your socials, website, store, videos, music, podcast, events and more. It all comes together in a link in bio landing page designed to convert."
                    )}
                  </p>
                </div>
                <div className=" mx-auto  ">
                  <img
                    alt=""
                    className="h-64 mx-auto "
                    src={`${process.env.PUBLIC_URL}/CV.svg`}
                  />
                  <p className="text-2xl  text-gray-700 my-5">
                    {t("Help your followers")}
                  </p>
                  <p className="text-gray-500 text-sm mt-4">
                    {t(
                      "Help your followers discover everything you’re sharing all over the internet, in one simple place. They’ll thank you for it!"
                    )}
                  </p>
                </div>
                <div>
                  <img
                    alt=""
                    className="h-64 "
                    src={`${process.env.PUBLIC_URL}/social.svg`}
                  />
                  <p className="text-2xl  text-gray-700 my-5">
                    {t("Share it everywhere!")}
                  </p>
                  <p className="text-gray-500 text-sm mt-4">
                    {t(
                      "Add your unique Linkakam URL to all the platforms and places  you find your audience. Then use your QR code to drive your offline traffic online."
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-gray-200 ">
            <div className="w-10/12 md:w-9/12 md:flex items-center  mx-auto p-2 py-20 justify-between  ">
              <div className="basis-6/12 relative py-10 h-72">
                <div className="w-24 md:w-30 md:h-20 animateY p-1 absolute top-0 left-10 rounded-xl bg-blue-400 shadow">
                  <p className=" text-center ">
                    <i className="fab fa-twitter text-xl text-white"></i>
                  </p>

                  <div className="flex justify-between ">
                    <img
                      src="https://cdn.wlo.link/static/landing/people/john_hill.png"
                      className="w-8 rounded-full object-cover h-8"
                      alt=""
                    />
                    <div className="basis-8/12 ">
                      <div className=" bg-slate-300 rounded-full animate-plus  h-3  w-full"></div>
                      <div className=" bg-slate-300 rounded-full animate-plus  h-3 mt-2   w-8/12"></div>
                    </div>
                  </div>
                </div>

                <div className="w-24 md:w-36 md:h-30 animateY2 p-3 absolute top-10 left-2/3 md:left-2/3 rounded-xl bg-red-600 shadow">
                  <p className=" text-center ">
                    <i className="fab fa-youtube text-3xl text-white"></i>
                  </p>

                  <div className="flex justify-between ">
                    <img
                      src="https://cdn.wlo.link/static/landing/people/kate.png"
                      className="w-12 rounded-full object-cover h-12"
                      alt=""
                    />
                    <div className="basis-8/12 pt-2 px-1">
                      <div className=" bg-slate-300 rounded-full animate-plus  h-3  w-full"></div>
                      <div className=" bg-slate-300 rounded-full animate-plus  h-3 mt-2   w-8/12"></div>
                    </div>
                  </div>
                </div>

                <div className="w-32 md:w-40 md:h-28 animateY p-3 absolute -top-10 left-2/3 md:left-1/3 rounded-xl bg-[#2a67bc] shadow">
                  <p className=" text-center ">
                    <i className="fa-brands fa-linkedin text-3xl text-white"></i>
                  </p>

                  <div className="flex justify-between ">
                    <img
                      src="https://cdn.wlo.link/static/landing/people/adnan.png"
                      className="w-12 rounded-full object-cover h-12"
                      alt=""
                    />
                    <div className="basis-8/12 pt-2 px-1">
                      <div className=" bg-slate-300 rounded-full animate-plus  h-3  w-full"></div>
                      <div className=" bg-slate-300 rounded-full animate-plus  h-3 mt-2   w-8/12"></div>
                    </div>
                  </div>
                </div>

                <div className="w-32 md:w-40 md:h-28 animateY p-3 absolute top-52 left-2/4 md:left-1/10 rounded-xl bg-blue-500 shadow">
                  <p className=" text-center ">
                    <i className="fab fa-facebook text-3xl text-white"></i>
                  </p>
                  <div className="flex justify-between ">
                    <img
                      src="https://cdn.wlo.link/static/landing/people/sara_connor.png"
                      className="w-12 rounded-full object-cover h-12"
                      alt=""
                    />
                    <div className="basis-8/12 pt-2 px-1">
                      <div className=" bg-slate-300 rounded-full animate-plus  h-3  w-full"></div>
                      <div className=" bg-slate-300 rounded-full animate-plus  h-3 mt-2   w-8/12"></div>
                    </div>
                  </div>
                </div>

                <div className="w-32 md:w-40 md:h-28 animateY2 p-3 absolute top-1/4 -left-10 md:left-14 rounded-xl bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 shadow">
                  <p className=" text-center ">
                    <i className="fab fa-instagram text-3xl text-white"></i>
                  </p>
                  <div className="flex justify-between ">
                    <img
                      src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29ufGVufDB8fDB8fA%3D%3D&w=1000&q=80"
                      className="w-12 rounded-full object-cover h-12"
                      alt=""
                    />
                    <div className="basis-8/12 pt-2 px-1">
                      <div className=" bg-slate-300 rounded-full animate-plus  h-3  w-full"></div>
                      <div className=" bg-slate-300 rounded-full animate-plus  h-3 mt-2   w-8/12"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="basis-6/12 mt-20 md:mt-0">
                <p className="text-3xl  text-gray-600">
                  {t("Multiple links in one place")}
                </p>
                <p className="mt-5 text-gray-500">
                  {t(
                    "our bio link tool helps promote your online presence, connect with your audience, and turn your bio into a landing page for your most important link"
                  )}
                </p>
                <div className=" flex  justify-end">
                  <button
                    onClick={() => {
                      window.location.href = "/en/setup";
                    }}
                    className="bg-blue-500 text-white px-3 py-2 rounded-xl mt-12  "
                  >
                    {t("Get Your Free Link Now")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="w-11/12 mt-10  md:w-9/12 mx-auto">
            <button
              onClick={() => {
                setProfiles([]);
              }}
            >
              <i className="fas fa-times text-3xl"></i>
            </button>

            <div className="mt-4 p-2 rounded-lg grid grid-cols-1 text-center gap-10 md:grid-cols-3">
              {profiles.map((element) => (
                <Link
                  to={`/${element.username}`}
                  className="shadow relative rounded-lg profile-link"
                  key={element.username}
                >
                  <img
                    alt=""
                    src={
                      element?.image
                        ? `https://api.linkakam.com/profile/${element.image}`
                        : "https://lh3.googleusercontent.com/a2hEzZGoZpBjv_bTb1Ro63cUQQiIGSirOjBf_npgA-nwwPNy7ZGkt1yCbGmnx_yYWAIAS44sMTzXPD4y1TnriWjzfUHxVTuwFzWVDQ=w600"
                    }
                    className="rounded-lg w-full h-60 object-cover"
                  />
                  <p className="">
                    {element.username}
                  </p>
                </Link>
              ))}
            </div>

          </div>
        </>
      )}
    </>
  );
};

export default Home;
