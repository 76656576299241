import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_EN } from "./locals/en/translations";
import { TRANSLATIONS_AR } from "./locals/ar/translations";
import { TRANSLATIONS_KU } from "./locals/ku/translations";
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      ar: {
        translation: TRANSLATIONS_AR,
      },
      ku: {
        translation: TRANSLATIONS_KU,
      },
    },
  });

i18n.changeLanguage(
  localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
);
localStorage.setItem(
  "lang",
  localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
);

export default i18n;
