import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "../style/getCv.css";
import Swal from "sweetalert2";
import imageCompression from "browser-image-compression";
import Qr from "../componant/QrCv";
import Modal from "react-modal";
import html2canvas from "html2canvas";

import { useTranslation } from "react-i18next";

function GetCv() {
  let formData = new FormData();

  const { t } = useTranslation();
  const [user, setUser] = useState(null);
  const [cv, setCv] = useState(null);
  const [imgchange, setimgchange] = useState(false);
  const [picture, setpicture] = useState(" ");
  const [havepic, sethavepic] = useState(false);
  const [showpicture, setshowpicture] = useState();
  const [imgSet, setimgSet] = useState(false);
  const [temp, settemp] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const inputFieldsSkill = useRef([{ singleSkill: "" }]);
  const inputFieldsPalette = useRef([{ first: "", second: "", third: "" }]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [show, setShow] = useState(false);

  const customStyles = {
    content: {
      borderRadius: 20,
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  function afterOpenModal() {}

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  const handlePaletteChange = (event) => {
    // Assuming the value is a JSON string representing an array
    const selectedPalette = JSON.parse(event.target.value)[0];
    inputFieldsPalette.current = selectedPalette;
    setRefresh((prev) => !prev);
  };

  function ProgressBar({ percentage }) {
    const validPercentage = percentage ? percentage : "0%";
    return (
      <div className="w-full bg-gray-200 rounded-full h-2 dark:bg-gray-800">
        <div
          className="h-2 rounded-full"
          style={{
            width: validPercentage,
            backgroundColor: inputFieldsPalette.current.third,
          }}
        ></div>
      </div>
    );
  }

  async function handleImageUpload(event) {
    let size = event.target.files[0].size / 1049 / 1000;
    console.log(size + "in mb");
    if (size < 2) {
      setimgchange(true);
      setpicture(event.target.files[0]);
      setshowpicture(URL.createObjectURL(event.target.files[0]));
    } else {
      const imageFile = event.target.files[0];
      console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
      console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

      const options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);
        console.log(
          "compressedFile instanceof Blob",
          compressedFile instanceof Blob
        ); // true
        console.log(
          `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
        ); // smaller than maxSizeMB

        setimgchange(true);
        setimgSet(true);
        setpicture(compressedFile);
        inputFieldsInfoRef.current = {
          ...inputFieldsInfoRef.current,
          image: compressedFile,
        };
        setshowpicture(URL.createObjectURL(event.target.files[0]));
      } catch (error) {
        // console.log(error);
      }
    }
  }

  const inputFieldsContact = useRef({
    phone: "",
    email: "",
    website: "",
  });

  const handleInputChangeContact = (e) => {
    const { name, value } = e.target;
    setRefresh((prev) => !prev);

    inputFieldsContact.current = {
      ...inputFieldsContact.current,
      [name]: value,
    };
  };

  // PERSONAL INFO
  const inputFieldsInfoRef = useRef({
    id: "",
    name: "",
    experties: "",
    address: "",
    birthdate: "",
    about: "",
    image: "",
    employmentType: "",
    state: false,
  });
  const [isChecked, setIsChecked] = useState(inputFieldsInfoRef.current.state);

  const handleInputChange = (e) => {
    // This line extracts the name, type, checked, and value properties from the event's target (the input element that triggered the event).
    const { name, type, checked, value } = e.target;
    setRefresh((prev) => !prev);
    // Handle checkbox separately
    if (type === "checkbox") {
      setIsChecked(checked);
      inputFieldsInfoRef.current[name] = checked;
    } else {
      // Handle other inputs like text, number, etc.
      inputFieldsInfoRef.current[name] = value;
    }
  };
  // EDUCATION
  const inputFieldsEdu = useRef([
    { university: "", yearEdu: "", status: "", degree: "" },
  ]);

  const removeEdu = (index) => {
    const newArray = [...inputFieldsEdu.current];
    newArray.splice(index, 1);
    inputFieldsEdu.current = newArray;
    setRefresh((prev) => !prev);
  };
  const [countEdu, setCountEdu] = useState(0);

  const addFieldsEdu = () => {
    if (countEdu >= 3) {
      alert("You cannot add more than 3 education fields.");
      return;
    }

    setCountEdu((prevCount) => prevCount + 1);
    const newArray = [
      ...inputFieldsEdu.current,
      { university: "", yearEdu: "", department: "", status: "", degree: "" },
    ];
    inputFieldsEdu.current = newArray;

    setRefresh((prev) => !prev);
  };

  const handleFormChangeEdu = (index, event) => {
    let data = [...inputFieldsEdu.current];
    data[index][event.target.name] = event.target.value;
    inputFieldsEdu.current = data;
    setRefresh((prev) => !prev);
  };

  const concatenateFieldsEdu = () => {
    const fieldStrings = inputFieldsEdu.current.map((field) => {
      return {
        university: field.university,
        yearEdu: field.yearEdu,
        degree: field.degree,
        status: field.status,
        department: field.department,
      };
    });
    return fieldStrings;
  };
  const concatenateFieldsskill = () => {
    const fieldStrings = inputFieldsSkill.current.map((field) => {
      return {
        singleSkill: field.singleSkill,
      };
    });
    return fieldStrings;
  };

  //LINK
  const links = useRef([{ social_name: "", icon: "", link: "" }]);

  //jOB
  const inputFieldsJob = useRef([{ position: "", place: "", year2: "" }]);

  const removeJob = (index) => {
    const newArray = [...inputFieldsJob.current];
    newArray.splice(index, 1);
    inputFieldsJob.current = newArray;
    setRefresh((prev) => !prev);
  };
  const [countJob, setCountJob] = useState(0);
  const addFieldsJob = () => {
    if (countJob >= 7) {
      alert("You cannot add more than 7 work experience fields.");
      return;
    }

    setCountJob((prevCount) => prevCount + 1);
    const newArray = [
      ...inputFieldsJob.current,
      { position: "", place: "", year2: "" },
    ];
    inputFieldsJob.current = newArray;
    setRefresh((prev) => !prev);
  };

  const handleFormChangeJob = (index, event) => {
    let data = [...inputFieldsJob.current];
    data[index][event.target.name] = event.target.value;
    inputFieldsJob.current = data;
    setRefresh((prev) => !prev);
  };

  const concatenateFieldsJob = () => {
    const fieldStrings = inputFieldsJob.current.map((field) => {
      return {
        position: field.position,
        place: field.place,
        year2: field.year2,
      };
    });
    return fieldStrings;
  };

  //SKILLs

  const [countSkill, setCountSkill] = useState(0);

  const addFieldsSkill = () => {
    if (countSkill >= 10) {
      alert("You cannot add more than 10 skill fields.");
      return;
    }
    setCountSkill((prevCount) => prevCount + 1);
    const newField = { singleSkill: "" };
    inputFieldsSkill.current = [...inputFieldsSkill.current, newField];
    setRefresh((prev) => !prev);
  };
  const removeSkill = (index) => {
    const updatedFields = inputFieldsSkill.current.filter(
      (_, i) => i !== index
    );
    inputFieldsSkill.current = updatedFields;
    setRefresh((prev) => !prev);
  };

  const handleFormChangeSkill = (index, event) => {
    let data = [...inputFieldsSkill.current];
    data[index][event.target.name] = event.target.value;
    inputFieldsSkill.current = data;
    setRefresh((prev) => !prev);
  };

  const inputFieldsLang = useRef([{ language: "", level: "" }]);

  const removeLang = (index) => {
    const updatedFields = inputFieldsLang.current.filter((_, i) => i !== index);
    inputFieldsLang.current = updatedFields;
    setRefresh((prev) => !prev); // Trigger a re-render
  };

  const [countLang, setCountLang] = useState(0);

  const addFieldsLang = () => {
    if (countLang >= 4) {
      alert("You cannot add more than 4 language fields.");
      return;
    }
    setCountLang((prevCount) => prevCount + 1);
    const newField = { lang: "", level: "" };
    inputFieldsLang.current = [...inputFieldsLang.current, newField];
    setRefresh((prev) => !prev); // Trigger a re-render
  };

  const handleFormChangeLang = (index, event) => {
    let data = [...inputFieldsLang.current];
    data[index][event.target.name] = event.target.value;
    inputFieldsLang.current = data;
    setRefresh((prev) => !prev);
  };

  const concatenateFieldsLang = () => {
    if (Array.isArray(inputFieldsLang.current)) {
      return inputFieldsLang.current.map((field) => ({ ...field }));
    }
    return []; // Return an empty array if inputFieldsLang.current is not an array
  };

  const combineAllFields = () => {
    const allFields = {
      lang: concatenateFieldsLang(),
      job: concatenateFieldsJob(),
      edu: concatenateFieldsEdu(),
      skill: concatenateFieldsskill(),
      info: inputFieldsInfoRef.current,
      contact: inputFieldsContact,
      palette: inputFieldsPalette.current,
    };
    console.log("datay checked", inputFieldsInfoRef.current.state);

    return allFields;
  };
  const sendDataToServer = async () => {
    const data = combineAllFields();
    formData.append("info", JSON.stringify(data.info));
    formData.append("contact", JSON.stringify(data.contact));
    formData.append("language", JSON.stringify(data.lang));
    formData.append("skills", JSON.stringify(data.skill));
    formData.append("education", JSON.stringify(data.edu));
    formData.append("work", JSON.stringify(data.job));
    formData.append("palette", JSON.stringify(inputFieldsPalette.current));
    formData.append("user_id", user.id);

    if (picture?.size || false) {
      formData.append("avatar", picture);
    }
    console.log("formdata", formData);
    try {
      const response = await axios.post(
        "https://api.linkakam.com/api/cv/store",

        formData
      );
      Swal.fire({
        icon: "success",
        title: "Inserted successfully",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "error",
        text: error,
      });
    }
  };
  const submit = (e) => {
    e.preventDefault();
    sendDataToServer();
  };
  useEffect(() => {
    axios
      .get("https://api.linkakam.com/api/cv")
      .then((response) => {
        setUser(response.data.data);

        const newAryInfo = response.data.cv.map((element) => {
          const infoObj = JSON.parse(element.info);
          const imageObj = element.image;
          console.log("imageObj", imageObj);
          if (imageObj !== null) {
            console.log("its null");
            sethavepic(true);
            setshowpicture(imageObj);
            setpicture(imageObj);
          }
          return {
            name: infoObj.name,
            experties: infoObj.experties,
            address: infoObj.address,
            birthdate: infoObj.birthdate,
            about: infoObj.about,
            employmentType: infoObj.employmentType,
            state: infoObj.state,
          };
        });

        if (newAryInfo.length > 0) {
          inputFieldsInfoRef.current = newAryInfo[0];
        }
        const newAryLink = response.data.links.map((element) => {
          return {
            social_name: element.social_name,
            icon: element.icon,
            link: element.link,
          };
        });

        const newAryContact = response.data.cv.map((element) => {
          const infoObj = JSON.parse(element.contact).current;
          return {
            phone: infoObj.phone,
            email: infoObj.email,
            website: infoObj.website || "",
          };
        });

        if (newAryContact.length > 0) {
          inputFieldsContact.current = newAryContact[0];
        }

        if (newAryLink.length > 0) {
          links.current = newAryLink;
        }

        if (response.data.cv && response.data.cv.length > 0) {
          const languageData = JSON.parse(response.data.cv[0].language);
          inputFieldsLang.current = languageData;

          const SkillData = JSON.parse(response.data.cv[0].skills);
          inputFieldsSkill.current = SkillData;

          const eduData = JSON.parse(response.data.cv[0].education);
          inputFieldsEdu.current = eduData;

          const jobData = JSON.parse(response.data.cv[0].work);
          inputFieldsJob.current = jobData;
          setRefresh((prev) => !prev); // Trigger a re-render
        }

        if (newAryContact.length > 0) {
          inputFieldsContact.current = newAryContact[0];
        }

        const newAryPalette = response.data.cv.map((element) => {
          try {
            const paletteObj = JSON.parse(element.palette);
            return {
              first: paletteObj.first || "#defaultFirstColor",
              second: paletteObj.second || "#defaultSecondColor",
              third: paletteObj.third || "#defaultThirdColor",
            };
          } catch (error) {
            console.error("Error parsing JSON:", error);
            return {
              first: "#defaultFirstColor",
              second: "#defaultSecondColor",
              third: "#defaultThirdColor",
            };
          }
        });

        if (newAryPalette.length > 0) {
          inputFieldsPalette.current = newAryPalette[0];
          console.log("colors:", inputFieldsPalette.current);
        }
      })
      .catch((error) => console.error("Error:", error));
  }, []);
  function formatTextWithLineBreaks(text, maxChars) {
    const result = [];
    for (let i = 0; i < text.length; i += maxChars) {
      result.push(text.substring(i, Math.min(i + maxChars, text.length)));
    }
    return result;
  }

  function printSection() {
    window.print();
  }

  const aboutText = inputFieldsInfoRef.current.about;
  const formattedAbout = formatTextWithLineBreaks(aboutText, 40);

  const printRef = React.useRef();

  const handleDownload = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const image = canvas.toDataURL("image/png", 1.0);

    // Create a link to download the image
    const link = document.createElement("a");
    link.href = image;
    link.download = "section-image.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    console.log("Toggle state is now: ", isChecked);
  }, [isChecked]);
  return (
    <div>
      <div className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500  flex  flex-col h-full justify-center items-center  ">
        {user && (
          <h1 className="text-white text-3xl uppercase mb-8 mt-2">
            {user.username}
          </h1>
        )}
        <div className="flex justify-center md:justify-end w-screen mb-2 md:mr-[40%]">
          <button
            onClick={printSection}
            className="bg-slate-800 rounded-lg text-white py-2 px-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-printer-fill"
              viewBox="0 0 16 16"
            >
              <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1" />
              <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
            </svg>
          </button>

          <button
            onClick={handleDownload}
            className="bg-slate-800 rounded-lg text-white py-1 ml-2 px-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-box-arrow-down"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M3.5 10a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h2A1.5 1.5 0 0 0 14 9.5v-8A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5v8A1.5 1.5 0 0 0 3.5 11h2a.5.5 0 0 0 0-1z"
              />
              <path
                fill-rule="evenodd"
                d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708z"
              />
            </svg>
          </button>
          <button
            onClick={openModal}
            className="bg-slate-800 rounded-lg ml-1 text-white py-2 px-2 md:hidden"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-eye"
              viewBox="0 0 16 16"
            >
              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
            </svg>
          </button>
        </div>

        <div className=" flex-row gap-40 mb-2 hidden md:flex ">
          <div className="col-md-6 mr-2 rounded-lg bg-white p-4 w-[660px] h-full">
            <div class="flex items-center w-full justify-center space-x-6 bg-white p-4">
              {/* colors */}
              <div class="flex flex-row ">
                <div class="flex items-center">
                  <input
                    type="radio"
                    id="palette1"
                    name="palette"
                    value='[{"first":"#0c4a6e","second":"#22d3ee","third":"white"}]'
                    className="form-radio"
                    onChange={handlePaletteChange}
                  />
                  <div className="flex flex-row">
                    <div className="h-5 w-5 bg-[#0c4a6e] ml-1"></div>
                    <div className="h-5 w-5 bg-[#22d3ee] "></div>
                    <div
                      className="h-5 w-5 bg-white mr-2 "
                      style={{ border: "1px solid gray" }}
                    ></div>
                  </div>
                </div>
                <div class="flex items-center">
                  <input
                    type="radio"
                    id="palette2"
                    name="palette"
                    value='[{"first":"#4C4C6D","second":"#FFE194","third":"#E8F6EF"}]'
                    className="form-radio"
                    onChange={handlePaletteChange}
                  />
                  <div className="flex flex-row">
                    <div className="h-5 w-5 bg-[#4C4C6D] ml-1"></div>
                    <div className="h-5 w-5 bg-[#FFE194] "></div>
                    <div className="h-5 w-5 bg-[#E8F6EF] mr-2 "></div>
                  </div>
                </div>
                <div class="flex items-center">
                  <input
                    type="radio"
                    id="palette3"
                    name="palette"
                    value='[{"first":"#1F4172","second":"#F1B4BB","third":"#FDF0F0"}]'
                    className="form-radio"
                    onChange={handlePaletteChange}
                  />
                  <div className="flex flex-row">
                    <div className="h-5 w-5 bg-[#1F4172] ml-1"></div>
                    <div className="h-5 w-5 bg-[#F1B4BB] "></div>
                    <div className="h-5 w-5 bg-[#FDF0F0] mr-2 "></div>
                  </div>
                </div>

                <div class="flex items-center">
                  <input
                    type="radio"
                    id="palette4"
                    name="palette"
                    value='[{"first":"#4F4A45","second":"#ED7D31","third":"#F6F1EE"}]'
                    className="form-radio"
                    onChange={handlePaletteChange}
                  />
                  <div className="flex flex-row">
                    <div className="h-5 w-5 bg-[#4F4A45] ml-1"></div>
                    <div className="h-5 w-5 bg-[#ED7D31] "></div>
                    <div className="h-5 w-5 bg-[#F6F1EE] mr-2 "></div>
                  </div>
                </div>

                <div class="flex items-center">
                  <input
                    type="radio"
                    id="palette4"
                    name="palette"
                    value='[{"first":"#607274","second":"#DED0B6","third":"#FAEED1"}]'
                    className="form-radio"
                    onChange={handlePaletteChange}
                  />
                  <div className="flex flex-row">
                    <div className="h-5 w-5 bg-[#607274] ml-1"></div>
                    <div className="h-5 w-5 bg-[#DED0B6] "></div>
                    <div className="h-5 w-5 bg-[#FAEED1] mr-2 "></div>
                  </div>
                </div>

                <div class="flex items-center">
                  <input
                    type="radio"
                    id="palette4"
                    name="palette"
                    value='[{"first":"#4A3933","second":"#B68973","third":"#EABF9F"}]'
                    className="form-radio"
                    onChange={handlePaletteChange}
                  />
                  <div className="flex flex-row">
                    <div className="h-5 w-5 bg-[#4A3933] ml-1"></div>
                    <div className="h-5 w-5 bg-[#B68973] "></div>
                    <div className="h-5 w-5 bg-[#EABF9F] mr-2 "></div>
                  </div>
                </div>

                <div class="flex items-center">
                  <input
                    type="radio"
                    id="palette4"
                    name="palette"
                    value='[{"first":"#2D4059","second":"#EA5455","third":"#FFD460"}]'
                    className="form-radio"
                    onChange={handlePaletteChange}
                  />
                  <div className="flex flex-row">
                    <div className="h-5 w-5 bg-[#2D4059] ml-1"></div>
                    <div className="h-5 w-5 bg-[#EA5455] "></div>
                    <div className="h-5 w-5 bg-[#FFD460] mr-2 "></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-gray-100 p-2  rounded-lg">
              <label className="items-center">
                <>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    onChange={(event) => {
                      handleImageUpload(event);
                    }}
                  />
                  {!imgchange ? (
                    havepic ? (
                      <img
                        className="z-50 "
                        alt="linkakam"
                        style={{
                          borderTopLeftRadius: "50% 50%",
                          borderTopRightRadius: "50% 50%",
                          borderBottomRightRadius: "50% 50%",
                          borderBottomLeftRadius: "50% 50%",
                          width: "55px",
                          boxShadow: "0 0 5px rgb(129, 129, 129)",
                          borderColor: inputFieldsPalette?.current.second,
                          aspectRatio: "1 / 1",
                          objectFit: "cover",
                          margin: "0 auto",
                        }}
                        src={"https://api.linkakam.com/cv/" + picture}
                      />
                    ) : (
                      <img
                        style={{
                          borderTopLeftRadius: "50% 50%",
                          borderTopRightRadius: "50% 50%",
                          borderBottomRightRadius: "50% 50%",
                          borderBottomLeftRadius: "50% 50%",
                          width: "55px",
                          boxShadow: "0 0 5px rgb(129, 129, 129)",

                          aspectRatio: "1 / 1",
                          objectFit: "cover",
                          margin: "0 auto",
                        }}
                        alt="linkakam"
                        src={`${process.env.PUBLIC_URL}/images/pp.png`}
                      />
                    )
                  ) : (
                    <>
                      <img
                        style={{
                          borderTopLeftRadius: "50% 50%",
                          borderTopRightRadius: "50% 50%",
                          borderBottomRightRadius: "50% 50%",
                          borderBottomLeftRadius: "50% 50%",
                          width: "55px",
                          boxShadow: "0 0 5px rgb(129, 129, 129)",
                          borderColor: inputFieldsPalette?.current.second,
                          aspectRatio: "1 / 1",
                          objectFit: "cover",
                          margin: "0 auto",
                        }}
                        alt="linkakam"
                        src={showpicture}
                      />
                    </>
                  )}
                </>
              </label>
              <div className="flex justify-center mt-2">
                <span class="ms-3 text-sm font-medium text-gray-600">
                  {t("You got a Job?")}
                </span>
              </div>
              <div className="flex justify-center mt-2">
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    name="state" // make sure this matches the field in your ref
                    checked={isChecked}
                    onChange={handleInputChange}
                  />
                  <div
                    className={`w-11 h-5.5 px-1 ${
                      isChecked ? "bg-blue-600" : "bg-gray-200"
                    } rounded-full transition-all`}
                  >
                    <div
                      className={`transform duration-300 ease-in-out ${
                        isChecked ? "translate-x-5" : "translate-x-0"
                      } bg-white w-5 h-5 rounded-full`}
                    ></div>
                  </div>
                </label>
              </div>
              <p className="text-center text-sm mt-2">Personal Information</p>

              <input
                type="hidden"
                name="id"
                value={(inputFieldsInfoRef.current.id = user?.id)}
                onChange={handleInputChange}
              />
              <input
                type="text"
                className="form-control inputs w-full mr-2"
                placeholder={t("Name")}
                required
                name="name"
                value={inputFieldsInfoRef.current.name}
                onChange={handleInputChange}
              />

              <input
                type="text"
                className="form-control inputs w-[196px] mr-2"
                placeholder={t("Experties")}
                name="experties"
                value={inputFieldsInfoRef.current.experties}
                onChange={handleInputChange}
              />
              <input
                type="text"
                className="form-control inputs w-[196px] mr-2"
                placeholder={t("Address")}
                name="address"
                value={inputFieldsInfoRef.current.address}
                onChange={handleInputChange}
              />
              <input
                type="text"
                className="form-control inputs w-[195px] mr-2"
                placeholder={t("Birthdate")}
                name="birthdate"
                value={inputFieldsInfoRef.current.birthdate}
                onChange={handleInputChange}
              />
              <textarea
                className="form-control inputs w-full"
                placeholder={t("About")}
                name="about"
                value={inputFieldsInfoRef.current.about}
                onChange={handleInputChange}
              />
            </div>

            <div className="bg-gray-100 p-2 mt-2 rounded-lg">
              <p className="text-center text-sm">{t("Contact Information")}</p>
              <input
                type="text"
                className="form-control inputs w-[40%] mr-2"
                placeholder={t("Phone")}
                name="phone"
                value={inputFieldsContact.current.phone}
                onChange={handleInputChangeContact}
              />
              <input
                type="text"
                className="form-control inputs w-[40%]  mr-2"
                placeholder={t("email")}
                name="email"
                value={inputFieldsContact.current.email}
                onChange={handleInputChangeContact}
              />
              {/* <input
                type="text"
                className="form-control inputs mr-2"
                placeholder={t("website")}
                name="website"
                value={inputFieldsContact.current.website}
                onChange={handleInputChangeContact}
              /> */}
            </div>

            {/* EDUCATION */}
            <div className="bg-gray-100 p-2 mt-2 rounded-lg">
              <p className="text-center text-sm">{t("Education")}</p>

              {inputFieldsEdu?.current.map((input, index) => {
                return (
                  <div key={index}>
                    <input
                      type="text"
                      className="form-control inputs w-32 mr-2"
                      placeholder={t("University")}
                      onChange={(event) => handleFormChangeEdu(index, event)}
                      value={input.university}
                      name="university"
                    />

                    <input
                      type="text"
                      className="form-control inputs w-32 mr-2"
                      placeholder={t("Department")}
                      onChange={(event) => handleFormChangeEdu(index, event)}
                      value={input.department}
                      name="department"
                    />
                    <input
                      type="text"
                      className="form-control inputs w-24  mr-2"
                      placeholder={t("Year")}
                      onChange={(event) => handleFormChangeEdu(index, event)}
                      value={input.yearEdu}
                      name="yearEdu"
                    />
                    <select
                      className="form-control mr-2 inputs"
                      onChange={(event) => handleFormChangeEdu(index, event)}
                      value={input.degree}
                      name="degree"
                    >
                      <option value="" disabled>
                        --Degree--
                      </option>
                      <option value="1">Bachelor</option>
                      <option value="0">Diploma</option>
                    </select>

                    <select
                      className="form-control inputs"
                      onChange={(event) => handleFormChangeEdu(index, event)}
                      value={input.status}
                      name="status"
                    >
                      <option value="" disabled>
                        --Status--
                      </option>
                      <option value="1">Graduated</option>
                      <option value="0">Student</option>
                    </select>

                    <button
                      type="button"
                      onClick={() => {
                        removeEdu(index);
                      }}
                      className="bg-red-700 w-6 text-center rounded ml-2 text-white"
                    >
                      <i className="fa-solid text-xs fa-trash"></i>
                    </button>
                  </div>
                );
              })}
            </div>
            <button
              type="button"
              className="gradient-button2 text-xs w-full  mr-2 px-3 py-1 rounded"
              onClick={addFieldsEdu}
            >
              {t("Add")}
            </button>

            {/* SKILL */}
            <div className="bg-gray-100 items-center p-2 mt-2 rounded-lg">
              <p className="text-center text-sm">{t("Skill Set")}</p>
              <div className="items-center">
                {inputFieldsSkill.current.map((input, index) => {
                  return (
                    <div key={index}>
                      <input
                        type="text"
                        className="form-control inputs w-96  mr-2"
                        placeholder={t("Skill")}
                        onChange={(event) =>
                          handleFormChangeSkill(index, event)
                        }
                        value={input.singleSkill}
                        name="singleSkill"
                      />
                      <button
                        type="button"
                        onClick={() => {
                          removeSkill(index);
                        }}
                        className="bg-red-700 w-6 text-center rounded ml-2 text-white"
                      >
                        <i className="fa-solid text-xs fa-trash"></i>
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
            <button
              type="button"
              className="gradient-button2 w-full text-xs  mr-2 px-3 py-1 rounded"
              onClick={addFieldsSkill}
            >
              {t("Add")}
            </button>

            {/* JOB */}
            <div className="bg-gray-100 p-2 mt-2 rounded-lg">
              <p className="text-center text-sm">{t("Work Experience")}</p>
              <div className="flex justify-center w-full mt-2">
                <input
                  type="radio"
                  name="employmentType"
                  value="employee"
                  className="mr-2 ml-2 text-xs"
                  checked={
                    inputFieldsInfoRef.current.employmentType === "employee"
                  }
                  onChange={handleInputChange}
                />
                <label className="text-sm" htmlFor="employee">
                  Employee
                </label>
                <input
                  type="radio"
                  name="employmentType"
                  value="freelance"
                  className="mr-2 ml-2 text-xs"
                  checked={
                    inputFieldsInfoRef.current.employmentType === "freelance"
                  }
                  onChange={handleInputChange}
                />
                <label className="text-sm" htmlFor="freelance">
                  Freelance
                </label>
                <input
                  type="radio"
                  name="employmentType"
                  value="remote"
                  className="mr-2 ml-2 text-xs"
                  checked={
                    inputFieldsInfoRef.current.employmentType === "remote"
                  }
                  onChange={handleInputChange}
                />{" "}
                <label className="text-sm" htmlFor="remote">
                  Remote
                </label>
              </div>
              {inputFieldsJob.current.map((input, index) => {
                return (
                  <div key={index}>
                    <input
                      type="text"
                      className="form-control inputs  mr-2"
                      placeholder={t("Position")}
                      onChange={(event) => handleFormChangeJob(index, event)}
                      value={input.position}
                      name="position"
                    />
                    <input
                      type="text"
                      className="form-control inputs w-24  mr-2"
                      placeholder={t("Place")}
                      onChange={(event) => handleFormChangeJob(index, event)}
                      value={input.place}
                      name="place"
                    />
                    <input
                      type="text"
                      className="form-control inputs  mr-2"
                      placeholder={t("Year")}
                      onChange={(event) => handleFormChangeJob(index, event)}
                      value={input.year2}
                      name="year2"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        removeJob(index);
                      }}
                      className="bg-red-700 w-6 text-center rounded ml-2 text-white"
                    >
                      <i className="fa-solid text-xs fa-trash"></i>
                    </button>
                  </div>
                );
              })}
            </div>
            <button
              type="button"
              className="gradient-button2 w-full text-xs  mr-2 px-3 py-1 rounded"
              onClick={addFieldsJob}
            >
              {t("Add")}
            </button>

            {/* LANGUAGE */}
            <div className="bg-gray-100 p-2 mt-2 rounded-lg">
              <p className="text-center text-sm">{t("Language")}</p>
              {inputFieldsLang.current.map((field, index) => {
                return (
                  <div key={index}>
                    <input
                      type="text"
                      className="form-control inputs  mr-2"
                      placeholder={t("Language")}
                      onChange={(event) => handleFormChangeLang(index, event)}
                      value={field.lang}
                      name="lang"
                    />
                    <select
                      className="form-control inputs"
                      onChange={(event) => handleFormChangeLang(index, event)}
                      value={field.level}
                      name="level"
                    >
                      <option value=""></option>
                      <option value="25%">%25</option>
                      <option value="35%">%35</option>
                      <option value="45%">%45</option>
                      <option value="55%">%55</option>
                      <option value="65%">%65</option>
                      <option value="75%">%75</option>
                      <option value="85%">%85</option>
                      <option value="95%">%95</option>
                      <option value="100%">%100</option>
                    </select>
                    <button
                      type="button"
                      onClick={() => {
                        removeLang(index);
                      }}
                      className="bg-red-700 w-6 text-center rounded ml-2 text-white"
                    >
                      <i className="fa-solid text-xs fa-trash"></i>
                    </button>
                  </div>
                );
              })}
            </div>
            <button
              type="button"
              className="gradient-button2 text-xs w-full mr-2 px-3 py-1 rounded"
              onClick={addFieldsLang}
            >
              {t("Add")}
            </button>
            <div>
              <button
                type="submit"
                onClick={submit}
                className="submitBtn mt-3 text-xs w-full mr-2 px-3 py-1 rounded"
              >
                {t("Submit")}
              </button>
            </div>
          </div>

          <div id="print-section" className="w-full h-[14.8cm]" ref={printRef}>
            <div className="col-md-6   bg-gray-50 w-[397px] max-h-[14.8cm] overflow-hidden ">
              <div className="flex flex-row">
                <div
                  className="col-md-6  p-2 w-[397px] h-[561.5px]"
                  style={{ backgroundColor: inputFieldsPalette.current.first }}
                >
                  <div className="p-2  ">
                    {console.log("imgchange:", imgchange)}
                    <label>
                      {/* Image rendering condition */}
                      {!imgchange ? (
                        havepic ? (
                          <img
                            className="z-50"
                            alt="linkakam"
                            src={`https://api.linkakam.com/cv/${picture}`}
                            style={{
                              borderRadius: "50%",
                              width: "55px",
                              height: "55px",
                              boxShadow: "0 0 5px rgb(129, 129, 129)",
                              border: "3px solid",
                              borderColor: inputFieldsPalette?.current.second,
                              objectFit: "cover",
                              margin: "0 auto",
                            }}
                          />
                        ) : null // Ensure this is null or a valid JSX element
                      ) : (
                        <img
                          style={{
                            borderRadius: "50%",
                            width: "55px",
                            height: "55px",
                            boxShadow: "0 0 5px rgb(129, 129, 129)",
                            borderColor: inputFieldsPalette?.current.second,
                            objectFit: "cover",
                            margin: "0 auto",
                          }}
                          alt="linkakam"
                          src={showpicture}
                        />
                      )}
                    </label>
                  </div>
                  <div className=" p-2">
                    <p
                      style={{ fontWeight: "bolder" }}
                      className="text-center text-md text-white uppercase "
                    >
                      {inputFieldsInfoRef.current.name}
                    </p>
                    <p
                      style={{
                        fontWeight: "thin",
                        color: inputFieldsPalette?.current.second, // This sets the text color
                      }}
                      className="text-center text-xs  capitalize "
                    >
                      {inputFieldsInfoRef.current.experties}
                    </p>
                  </div>
                  <div class="flex w-full">
                    {inputFieldsContact.current && (
                      <div class="flex-1 h-full " style={{}}>
                        <div
                          className="flex items-center h-3 mt-2 -ml-2"
                          style={{ borderLeft: "22px solid white" }}
                        >
                          <p
                            style={{ fontSize: "10px", fontWeight: "bolder" }}
                            className="text-left mb-1  text-white p-1  rounded mr-2 bold "
                          >
                            {t("CONTACT")}
                          </p>
                        </div>
                        <p
                          style={{ fontSize: "10px" }}
                          className="text-left text-white mt-2  capitalize flex"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            fill={inputFieldsPalette?.current?.second} // Set the fill attribute directly
                            style={{
                              color: inputFieldsPalette?.current.second,
                            }}
                            className="mr-1 bi bi-geo-alt-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                          </svg>
                          {inputFieldsInfoRef.current.address}
                        </p>

                        <p
                          style={{ fontSize: "10px" }}
                          className="text-left text-white  capitalize mt-1 flex"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            fill={inputFieldsPalette?.current?.second} // Set the fill attribute directly
                            style={{
                              color: inputFieldsPalette?.current.second,
                            }}
                            class="mr-1 bi bi-telephone-fill"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                            />
                          </svg>
                          {inputFieldsContact.current.phone}
                        </p>

                        <p
                          style={{ fontSize: "10px" }}
                          className="text-left text-white  capitalize mt-1 flex"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            fill={inputFieldsPalette?.current?.second} // Set the fill attribute directly
                            style={{
                              color: inputFieldsPalette?.current.second,
                            }}
                            class="mr-1 bi bi-envelope-at-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2zm-2 9.8V4.698l5.803 3.546L0 11.801Zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 9.671V4.697l-5.803 3.546.338.208A4.482 4.482 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671" />
                            <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791Z" />
                          </svg>
                          {inputFieldsContact.current.email}
                        </p>

                        <div className="">
                          <div
                            className="flex items-center h-3 mt-2 -ml-2"
                            style={{ borderLeft: "22px solid white" }}
                          >
                            <p
                              style={{ fontSize: "10px", fontWeight: "bolder" }}
                              className="text-left mb-1 mt-1  text-white p-1 uppercase  rounded mr-2 bold "
                            >
                              {t("Skill set")}
                            </p>
                          </div>
                          <div className="mt-2">
                            {inputFieldsSkill.current.map(
                              (singleSkill, index) => (
                                <div key={index} className="flex items-center">
                                  <p
                                    style={{ fontSize: "10px" }}
                                    className="text-left  text-white  capitalize flex"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="5"
                                      height="5"
                                      fill={inputFieldsPalette?.current?.second} // Set the fill attribute directly
                                      className="mt-1 mr-1 bi bi-circle-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <circle cx="8" cy="8" r="8" />
                                    </svg>
                                    {singleSkill.singleSkill}
                                  </p>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                        {/* language */}
                        <div className="">
                          <div
                            className="flex items-center h-3 mt-2 -ml-2"
                            style={{ borderLeft: "22px solid white" }}
                          >
                            <p
                              style={{ fontSize: "10px", fontWeight: "bolder" }}
                              className="text-left mb-1 mt-1  text-white p-1 uppercase  rounded mr-2 bold "
                            >
                              {t("Language")}
                            </p>
                          </div>
                          <div className="mt-2">
                            {inputFieldsLang.current.map((field, index) => (
                              <div key={index} className="flex flex-col mb-2">
                                <div className="flex items-center">
                                  <p
                                    style={{ fontSize: "10px" }}
                                    className="text-left text-white capitalize flex"
                                  >
                                    {field.lang}
                                  </p>
                                </div>
                                <ProgressBar percentage={`${field.level}`} />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6 rounded-lg bg-gray-50 w-[397px] h-[561.5px]">
                  <div class="flex-1 h-full ml-2">
                    {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div class="w-1/2 text-center items-center" style={{ borderTop: "8px solid #22d3ee", width: "50%" }}></div>
                  </div> */}
                    <div
                      className="-mt-2"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <p
                        style={{
                          fontSize: "10px",
                          fontWeight: "bolder",
                          backgroundColor: inputFieldsPalette.current.second,
                        }}
                        className="text-center uppercase mt-2 text-white mb-2   px-1 py-4 bold"
                      >
                        {t("About me")}
                      </p>
                    </div>
                    <p
                      style={{ fontSize: "10px" }}
                      className="text-center capitalize flex"
                    >
                      {formattedAbout.map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                    </p>
                    <p
                      style={{
                        fontSize: "10px",
                        fontWeight: "bolder",
                        color: inputFieldsPalette.current.second,
                      }}
                      className="text-center mt-2  p-1 rounded mr-2 bold"
                    >
                      {t("EDUCATION HISTORY")}
                    </p>
                    {inputFieldsEdu.current.map((edu, index) => (
                      <div key={index} className="mt-2">
                        <div
                          className="flex flex-col items-start "
                          style={{
                            paddingLeft: "2px",
                            borderLeft: "solid 12px black",
                            borderColor: inputFieldsPalette.current.second,
                          }}
                        >
                          <p className="text-left uppercase text-gray-700 text-smaller-spacing font-bold">
                            {edu.department}
                          </p>
                        </div>
                        <span className="text-left text-smaller-spacing">
                          {edu.degree === 0
                            ? "Diploma degree | " + edu.yearEdu
                            : "Bachelor degree | " + edu.yearEdu}
                        </span>
                        <span className="text-left text-smaller-spacing">
                          {edu.degree === 0
                            ? t("Graduated after 2 years of studying ") +
                              edu.department +
                              t(" at ") +
                              edu.university
                            : t("Graduated after 4 years of studying ") +
                              edu.department +
                              " at " +
                              edu.university}
                        </span>
                      </div>
                    ))}
                    <p
                      style={{
                        fontSize: "10px",
                        fontWeight: "bolder",
                        color: inputFieldsPalette.current.second,
                      }}
                      className="text-center mt-1  p-1 rounded mr-2 bold"
                    >
                      {t("WORK EXPERIENCE")}
                    </p>
                    {inputFieldsJob.current.map((job, index) => (
                      <div key={index} className="mt-1">
                        <div className="flex l items-start ">
                          <p
                            className="text-left  text-gray-700"
                            style={{ fontSize: "10px" }}
                          >
                            Worked as a{" "}
                            <span
                              className="uppercase"
                              style={{ fontWeight: "bold" }}
                            >
                              {job.position}{" "}
                            </span>
                            <span className="">
                              {t("at")} {job.place}
                            </span>
                            <span className="">
                              {" "}
                              {t("since")} {job.year2}
                            </span>
                          </p>
                        </div>
                      </div>
                    ))}
                    <p
                      style={{
                        fontSize: "10px",
                        fontWeight: "bolder",
                        color: inputFieldsPalette.current.second,
                      }}
                      className="text-center mt-3  p-1 rounded mr-2 bold"
                    >
                      {t("SOCIAL LINKS")}
                    </p>
                    <div className="link-container">
                      {user ? (
                        <div className="qr-container">
                          <Qr
                            url2={
                              "https://linkakam.com/" + user.username.toString()
                            }
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* MOBILE */}
      <div className="lg:hidden ">
        <div className="col-md-6 mr-2 rounded-lg bg-white p-4 w-screen h-full">
          <div class="flex items-center w-full justify-center space-x-6 bg-white p-4">
            {/* colors */}
            <div class="flex flex-row ">
              <div class="flex items-center">
                <input
                  type="radio"
                  id="palette1"
                  name="palette"
                  value='[{"first":"#0c4a6e","second":"#22d3ee","third":"white"}]'
                  className="form-radio"
                  onChange={handlePaletteChange}
                />
                <div className="flex flex-row">
                  <div className="h-2 w-2 bg-[#0c4a6e] ml-1"></div>
                  <div className="h-2 w-2 bg-[#22d3ee] "></div>
                  <div
                    className="h-2 w-2 bg-white mr-2 "
                    style={{ border: "1px solid gray" }}
                  ></div>
                </div>
              </div>
              <div class="flex items-center">
                <input
                  type="radio"
                  id="palette2"
                  name="palette"
                  value='[{"first":"#4C4C6D","second":"#FFE194","third":"#E8F6EF"}]'
                  className="form-radio"
                  onChange={handlePaletteChange}
                />
                <div className="flex flex-row">
                  <div className="h-2 w-2 bg-[#4C4C6D] ml-1"></div>
                  <div className="h-2 w-2 bg-[#FFE194] "></div>
                  <div className="h-2 w-2 bg-[#E8F6EF] mr-2 "></div>
                </div>
              </div>
              <div class="flex items-center">
                <input
                  type="radio"
                  id="palette3"
                  name="palette"
                  value='[{"first":"#1F4172","second":"#F1B4BB","third":"#FDF0F0"}]'
                  className="form-radio"
                  onChange={handlePaletteChange}
                />
                <div className="flex flex-row">
                  <div className="h-2 w-2 bg-[#1F4172] ml-1"></div>
                  <div className="h-2 w-2 bg-[#F1B4BB] "></div>
                  <div className="h-2 w-2 bg-[#FDF0F0] mr-2 "></div>
                </div>
              </div>

              <div class="flex items-center">
                <input
                  type="radio"
                  id="palette4"
                  name="palette"
                  value='[{"first":"#4F4A45","second":"#ED7D31","third":"#F6F1EE"}]'
                  className="form-radio"
                  onChange={handlePaletteChange}
                />
                <div className="flex flex-row">
                  <div className="h-2 w-2 bg-[#4F4A45] ml-1"></div>
                  <div className="h-2 w-2 bg-[#ED7D31] "></div>
                  <div className="h-2 w-2 bg-[#F6F1EE] mr-2 "></div>
                </div>
              </div>

              <div class="flex items-center">
                <input
                  type="radio"
                  id="palette4"
                  name="palette"
                  value='[{"first":"#607274","second":"#DED0B6","third":"#FAEED1"}]'
                  className="form-radio"
                  onChange={handlePaletteChange}
                />
                <div className="flex flex-row">
                  <div className="h-2 w-2 bg-[#607274] ml-1"></div>
                  <div className="h-2 w-2 bg-[#DED0B6] "></div>
                  <div className="h-2 w-2 bg-[#FAEED1] mr-2 "></div>
                </div>
              </div>

              <div class="flex items-center">
                <input
                  type="radio"
                  id="palette4"
                  name="palette"
                  value='[{"first":"#4A3933","second":"#B68973","third":"#EABF9F"}]'
                  className="form-radio"
                  onChange={handlePaletteChange}
                />
                <div className="flex flex-row">
                  <div className="h-2 w-2 bg-[#4A3933] ml-1"></div>
                  <div className="h-2 w-2 bg-[#B68973] "></div>
                  <div className="h-2 w-2 bg-[#EABF9F] mr-2 "></div>
                </div>
              </div>

              <div class="flex items-center">
                <input
                  type="radio"
                  id="palette4"
                  name="palette"
                  value='[{"first":"#2D4059","second":"#EA5455","third":"#FFD460"}]'
                  className="form-radio"
                  onChange={handlePaletteChange}
                />
                <div className="flex flex-row">
                  <div className="h-2 w-2 bg-[#2D4059] ml-1"></div>
                  <div className="h-2 w-2 bg-[#EA5455] "></div>
                  <div className="h-2 w-2 bg-[#FFD460] mr-2 "></div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-gray-100 p-2  rounded-lg">
            <label className="items-center">
              <>
                <input
                  style={{ display: "none" }}
                  type="file"
                  accept="image/*"
                  onChange={(event) => {
                    handleImageUpload(event);
                  }}
                />
                {!imgchange ? (
                  havepic ? (
                    <img
                      className="z-50 "
                      alt="linkakam"
                      style={{
                        borderTopLeftRadius: "50% 50%",
                        borderTopRightRadius: "50% 50%",
                        borderBottomRightRadius: "50% 50%",
                        borderBottomLeftRadius: "50% 50%",
                        width: "55px",
                        boxShadow: "0 0 5px rgb(129, 129, 129)",
                        borderColor: inputFieldsPalette?.current.second,
                        aspectRatio: "1 / 1",
                        objectFit: "cover",
                        margin: "0 auto",
                      }}
                      src={"https://api.linkakam.com/cv/" + picture}
                    />
                  ) : (
                    <img
                      style={{
                        borderTopLeftRadius: "50% 50%",
                        borderTopRightRadius: "50% 50%",
                        borderBottomRightRadius: "50% 50%",
                        borderBottomLeftRadius: "50% 50%",
                        width: "55px",
                        boxShadow: "0 0 5px rgb(129, 129, 129)",

                        aspectRatio: "1 / 1",
                        objectFit: "cover",
                        margin: "0 auto",
                      }}
                      alt="linkakam"
                      src={`${process.env.PUBLIC_URL}/images/pp.png`}
                    />
                  )
                ) : (
                  <>
                    <img
                      style={{
                        borderTopLeftRadius: "50% 50%",
                        borderTopRightRadius: "50% 50%",
                        borderBottomRightRadius: "50% 50%",
                        borderBottomLeftRadius: "50% 50%",
                        width: "55px",
                        boxShadow: "0 0 5px rgb(129, 129, 129)",
                        borderColor: inputFieldsPalette?.current.second,
                        aspectRatio: "1 / 1",
                        objectFit: "cover",
                        margin: "0 auto",
                      }}
                      alt="linkakam"
                      src={showpicture}
                    />
                  </>
                )}
              </>
            </label>
            <p className="text-center text-sm mt-2">Personal Information</p>
            <input
              type="text"
              className="form-control inputs w-full mr-2"
              placeholder={t("Name")}
              required
              name="name"
              value={inputFieldsInfoRef.current.name}
              onChange={handleInputChange}
            />
            <input
              type="text"
              className="form-control inputs w-full mr-2"
              placeholder={t("Experties")}
              name="experties"
              value={inputFieldsInfoRef.current.experties}
              onChange={handleInputChange}
            />
            <input
              type="text"
              className="form-control inputs w-full mr-2"
              placeholder={t("Address")}
              name="address"
              value={inputFieldsInfoRef.current.address}
              onChange={handleInputChange}
            />
            <input
              type="text"
              className="form-control inputs w-full mr-2"
              placeholder={t("Birthdate")}
              name="birthdate"
              value={inputFieldsInfoRef.current.birthdate}
              onChange={handleInputChange}
            />
            <textarea
              className="form-control inputs w-full"
              placeholder={t("About")}
              name="about"
              value={inputFieldsInfoRef.current.about}
              onChange={handleInputChange}
            />
          </div>

          <div className="bg-gray-100 p-2 mt-2 rounded-lg">
            <p className="text-center text-sm">{t("Contact Information")}</p>
            <input
              type="text"
              className="form-control inputs mr-2"
              placeholder={t("Phone")}
              name="phone"
              value={inputFieldsContact.current.phone}
              onChange={handleInputChangeContact}
            />
            <input
              type="text"
              className="form-control inputs mr-2"
              placeholder={t("email")}
              name="email"
              value={inputFieldsContact.current.email}
              onChange={handleInputChangeContact}
            />
            {/* <input
                type="text"
                className="form-control inputs mr-2"
                placeholder={t("website")}
                name="website"
                value={inputFieldsContact.current.website}
                onChange={handleInputChangeContact}
              /> */}
          </div>

          {/* EDUCATION */}
          <div className="bg-gray-100 p-2 mt-2 rounded-lg">
            <p className="text-center text-sm">{t("Education")}</p>
            {inputFieldsEdu.current.map((input, index) => {
              return (
                <div key={index}>
                  <input
                    type="text"
                    className="form-control inputs w-32 mr-2"
                    placeholder={t("University")}
                    onChange={(event) => handleFormChangeEdu(index, event)}
                    value={input.university}
                    name="university"
                  />

                  <input
                    type="text"
                    className="form-control inputs w-32 mr-2"
                    placeholder={t("Department")}
                    onChange={(event) => handleFormChangeEdu(index, event)}
                    value={input.department}
                    name="department"
                  />
                  <input
                    type="text"
                    className="form-control inputs w-24  mr-2"
                    placeholder={t("Year")}
                    onChange={(event) => handleFormChangeEdu(index, event)}
                    value={input.yearEdu}
                    name="yearEdu"
                  />
                  <select
                    className="form-control mr-2 inputs"
                    onChange={(event) => handleFormChangeEdu(index, event)}
                    value={input.degree}
                    name="degree"
                  >
                    <option value=""></option>
                    <option value="1">Bachelor</option>
                    <option value="0">Diploma</option>
                  </select>

                  <select
                    className="form-control inputs"
                    onChange={(event) => handleFormChangeEdu(index, event)}
                    value={input.status}
                    name="status"
                  >
                    <option value=""></option>
                    <option value="1">Graduated</option>
                    <option value="0">Student</option>
                  </select>

                  <button
                    type="button"
                    onClick={() => {
                      removeEdu(index);
                    }}
                    className="bg-red-700 w-6 text-center rounded ml-2 text-white"
                  >
                    <i className="fa-solid text-xs fa-trash"></i>
                  </button>
                </div>
              );
            })}
          </div>
          <button
            type="button"
            className="gradient-button2 text-xs w-full  mr-2 px-3 py-1 rounded"
            onClick={addFieldsEdu}
          >
            {t("Add")}
          </button>

          {/* SKILL */}
          <div className="bg-gray-100 items-center p-2 mt-2 rounded-lg">
            <p className="text-center text-sm">{t("Skill Set")}</p>
            <div className="items-center">
              {inputFieldsSkill.current.map((input, index) => {
                return (
                  <div key={index}>
                    <input
                      type="text"
                      className="form-control inputs w-[80%]  mr-2"
                      placeholder={t("Skill")}
                      onChange={(event) => handleFormChangeSkill(index, event)}
                      value={input.singleSkill}
                      name="singleSkill"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        removeSkill(index);
                      }}
                      className="bg-red-700 w-6 text-center rounded ml-2 text-white"
                    >
                      <i className="fa-solid text-xs fa-trash"></i>
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
          <button
            type="button"
            className="gradient-button2 w-full text-xs  mr-2 px-3 py-1 rounded"
            onClick={addFieldsSkill}
          >
            {t("Add")}
          </button>

          {/* JOB */}
          <div className="bg-gray-100 p-2 mt-2 rounded-lg">
            <p className="text-center text-sm">{t("Work Experience")}</p>
            {inputFieldsJob.current.map((input, index) => {
              return (
                <div key={index}>
                  <input
                    type="text"
                    className="form-control inputs w-24  mr-2"
                    placeholder={t("Position")}
                    onChange={(event) => handleFormChangeJob(index, event)}
                    value={input.position}
                    name="position"
                  />
                  <input
                    type="text"
                    className="form-control inputs w-24  mr-2"
                    placeholder={t("Place")}
                    onChange={(event) => handleFormChangeJob(index, event)}
                    value={input.place}
                    name="place"
                  />
                  <input
                    type="text"
                    className="form-control inputs w-12 mr-2"
                    placeholder={t("Year")}
                    onChange={(event) => handleFormChangeJob(index, event)}
                    value={input.year2}
                    name="year2"
                  />
                  <button
                    type="button"
                    onClick={() => {
                      removeJob(index);
                    }}
                    className="bg-red-700 w-6 text-center rounded ml-2 text-white"
                  >
                    <i className="fa-solid text-xs fa-trash"></i>
                  </button>
                </div>
              );
            })}
          </div>
          <button
            type="button"
            className="gradient-button2 w-full text-xs  mr-2 px-3 py-1 rounded"
            onClick={addFieldsJob}
          >
            {t("Add")}
          </button>

          {/* LANGUAGE */}
          <div className="bg-gray-100 p-2 mt-2 rounded-lg">
            <p className="text-center text-sm">{t("Language")}</p>
            {inputFieldsLang.current.map((field, index) => {
              return (
                <div key={index}>
                  <input
                    type="text"
                    className="form-control inputs w-48  mr-2"
                    placeholder={t("Language")}
                    onChange={(event) => handleFormChangeLang(index, event)}
                    value={field.lang}
                    name="lang"
                  />
                  <select
                    className="form-control inputs"
                    onChange={(event) => handleFormChangeLang(index, event)}
                    value={field.level}
                    name="level"
                  >
                    <option value=""></option>
                    <option value="25%">%25</option>
                    <option value="35%">%35</option>
                    <option value="45%">%45</option>
                    <option value="55%">%55</option>
                    <option value="65%">%65</option>
                    <option value="75%">%75</option>
                    <option value="85%">%85</option>
                    <option value="95%">%95</option>
                    <option value="100%">%100</option>
                  </select>
                  <button
                    type="button"
                    onClick={() => {
                      removeLang(index);
                    }}
                    className="bg-red-700 w-6 text-center rounded ml-2 text-white"
                  >
                    <i className="fa-solid text-xs fa-trash"></i>
                  </button>
                </div>
              );
            })}
          </div>
          <button
            type="button"
            className="gradient-button2 text-xs w-full mr-2 px-3 py-1 rounded"
            onClick={addFieldsLang}
          >
            {t("Add")}
          </button>
          <div>
            <button
              type="submit"
              onClick={submit}
              className="submitBtn mt-3 text-xs w-full mr-2 px-3 py-1 rounded"
            >
              {t("Submit")}
            </button>
          </div>
        </div>

        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="text-right ">
            <button
              style={{
                width: 100,
                height: 30,
                borderRadius: 10,
                color: "white",
                backgroundColor: "#2a5ce6",
              }}
              onClick={closeModal}
            >
              {t("close")}
            </button>
          </div>

          <div className="col-md-6 mr-2 rounded-lg bg-gray-50 p-4 w-[397px] h-[561.5px]">
            <div className="flex flex-row">
              <div
                className="col-md-6 rounded-lg p-2 w-[397px] h-[561.5px]"
                style={{ backgroundColor: inputFieldsPalette.current.first }}
              >
                <div className="p-2 rounded-lg al">
                  <label className="">
                    <>
                      {!imgchange ? (
                        havepic ? (
                          <img
                            className=" z-50 "
                            alt="linkakam"
                            src={"https://api.linkakam.com/profile/" + picture}
                            style={{
                              borderTopLeftRadius: "50% 50%",
                              borderTopRightRadius: "50% 50%",
                              borderBottomRightRadius: "50% 50%",
                              borderBottomLeftRadius: "50% 50%",
                              width: "55px",
                              boxShadow: "0 0 5px rgb(129, 129, 129)",
                              border: "3px solid",
                              borderColor: inputFieldsPalette?.current.second,
                              aspectRatio: "1 / 1",
                              objectFit: "cover",
                              margin: "0 auto",
                            }}
                          />
                        ) : (
                          <img
                            style={{
                              borderTopLeftRadius: "50% 50%",
                              borderTopRightRadius: "50% 50%",
                              borderBottomRightRadius: "50% 50%",
                              borderBottomLeftRadius: "50% 50%",
                              width: "55px",
                              boxShadow: "0 0 5px rgb(129, 129, 129)",
                              border: "3px solid",
                              borderColor: inputFieldsPalette?.current.second,
                              aspectRatio: "1 / 1",
                              objectFit: "cover",
                              margin: "0 auto",
                            }}
                            alt="linkakam"
                            src={`${process.env.PUBLIC_URL}/images/pp.png`}
                          />
                        )
                      ) : (
                        <>
                          <img
                            style={{
                              borderTopLeftRadius: "50% 50%",
                              borderTopRightRadius: "50% 50%",
                              borderBottomRightRadius: "50% 50%",
                              borderBottomLeftRadius: "50% 50%",
                              width: "55px",
                              boxShadow: "0 0 5px rgb(129, 129, 129)",
                              borderColor: inputFieldsPalette?.current.second,
                              aspectRatio: "1 / 1",
                              objectFit: "cover",
                              margin: "0 auto",
                            }}
                            alt="linkakam"
                            src={showpicture}
                          />
                        </>
                      )}
                    </>
                  </label>
                </div>
                <div className=" p-2">
                  <p
                    style={{ fontWeight: "bolder" }}
                    className="text-center text-md text-white uppercase "
                  >
                    {inputFieldsInfoRef.current.name}
                  </p>
                  <p
                    style={{
                      fontWeight: "thin",
                      color: inputFieldsPalette?.current.second, // This sets the text color
                    }}
                    className="text-center text-xs  capitalize "
                  >
                    {inputFieldsInfoRef.current.experties}
                  </p>
                </div>
                <div class="flex w-full">
                  <div class="flex-1 h-full " style={{}}>
                    <div
                      className="flex items-center h-3 mt-2 -ml-2"
                      style={{ borderLeft: "22px solid white" }}
                    >
                      <p
                        style={{ fontSize: "10px", fontWeight: "bolder" }}
                        className="text-left mb-1  text-white p-1  rounded mr-2 bold "
                      >
                        {t("CONTACT")}
                      </p>
                    </div>
                    <p
                      style={{ fontSize: "10px" }}
                      className="text-left text-white mt-2  capitalize flex"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill={inputFieldsPalette?.current?.second} // Set the fill attribute directly
                        style={{ color: inputFieldsPalette?.current.second }}
                        className="mr-1 bi bi-geo-alt-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                      </svg>
                      {inputFieldsInfoRef.current.address}
                    </p>

                    <p
                      style={{ fontSize: "10px" }}
                      className="text-left text-white  capitalize mt-1 flex"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill={inputFieldsPalette?.current?.second} // Set the fill attribute directly
                        style={{ color: inputFieldsPalette?.current.second }}
                        class="mr-1 bi bi-telephone-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                        />
                      </svg>
                      {inputFieldsContact.current.phone}
                    </p>

                    <p
                      style={{ fontSize: "10px" }}
                      className="text-left text-white  capitalize mt-1 flex"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill={inputFieldsPalette?.current?.second} // Set the fill attribute directly
                        style={{ color: inputFieldsPalette?.current.second }}
                        class="mr-1 bi bi-envelope-at-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2zm-2 9.8V4.698l5.803 3.546L0 11.801Zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 9.671V4.697l-5.803 3.546.338.208A4.482 4.482 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671" />
                        <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791Z" />
                      </svg>
                      {inputFieldsContact.current.email}
                    </p>

                    <div className="">
                      <div
                        className="flex items-center h-3 mt-2 -ml-2"
                        style={{ borderLeft: "22px solid white" }}
                      >
                        <p
                          style={{ fontSize: "10px", fontWeight: "bolder" }}
                          className="text-left mb-1 mt-1  text-white p-1 uppercase  rounded mr-2 bold "
                        >
                          {t("Skill set")}
                        </p>
                      </div>
                      <div className="mt-2">
                        {inputFieldsSkill.current.map((singleSkill, index) => (
                          <div key={index} className="flex items-center">
                            <p
                              style={{ fontSize: "10px" }}
                              className="text-left  text-white  capitalize flex"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="5"
                                height="5"
                                fill={inputFieldsPalette?.current?.second} // Set the fill attribute directly
                                className="mt-1 mr-1 bi bi-circle-fill"
                                viewBox="0 0 16 16"
                              >
                                <circle cx="8" cy="8" r="8" />
                              </svg>
                              {singleSkill.singleSkill}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                    {/* language */}
                    {inputFieldsLang.current.length > 0 && (
                      <div className="">
                        <div
                          className="flex items-center h-3 mt-2 -ml-2"
                          style={{ borderLeft: "22px solid white" }}
                        >
                          <p
                            style={{ fontSize: "10px", fontWeight: "bolder" }}
                            className="text-left mb-1 mt-1 text-white p-1 uppercase rounded mr-2 bold "
                          >
                            {t("Language")}
                          </p>
                        </div>
                        <div className="mt-2">
                          {inputFieldsLang.current.map((field, index) => (
                            <div key={index} className="flex flex-col mb-2">
                              <div className="flex items-center">
                                <p
                                  style={{ fontSize: "10px" }}
                                  className="text-left text-white capitalize flex"
                                >
                                  {field.lang}
                                </p>
                              </div>
                              {field.level && (
                                <ProgressBar percentage={`${field.level}`} />
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-6 rounded-lg bg-gray-50 w-[397px] h-[561.5px]">
                <div class="flex-1 h-full ml-2">
                  {formattedAbout.length > 0 && (
                    <>
                      <div
                        className="-mt-2"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p
                          style={{
                            fontSize: "10px",
                            fontWeight: "bolder",
                            backgroundColor: inputFieldsPalette.current.second,
                          }}
                          className="text-center uppercase mt-2 text-white mb-2 w-1/2 px-1 py-4 bold"
                        >
                          {t("About me")}
                        </p>
                      </div>
                      <p
                        style={{ fontSize: "10px" }}
                        className="text-center capitalize flex"
                      >
                        {formattedAbout.map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                      </p>
                    </>
                  )}

                  {inputFieldsEdu?.current.length > 0 ? (
                    <>
                      <p
                        style={{
                          fontSize: "10px",
                          fontWeight: "bolder",
                          color: inputFieldsPalette.current.second,
                        }}
                        className="text-center mt-2 p-1 rounded mr-2 bold"
                      >
                        {t("EDUCATION HISTORY")}
                      </p>

                      {inputFieldsEdu.current.map((edu, index) => (
                        <div key={index} className="mt-2">
                          <div
                            className="flex flex-col items-start"
                            style={{
                              paddingLeft: "2px",
                              borderLeft: "solid 12px",
                              borderColor: inputFieldsPalette.current.second,
                            }}
                          >
                            <p className="text-left uppercase text-gray-700 text-smaller-spacing font-bold">
                              {edu.department}
                            </p>
                          </div>
                          <span className="text-left text-smaller-spacing">
                            {edu.degree === 0
                              ? `Diploma degree | ${edu.yearEdu}`
                              : `Bachelor degree | ${edu.yearEdu}`}
                          </span>
                          <span className="text-left text-smaller-spacing">
                            {edu.degree === 0
                              ? `${t("Graduated after 2 years of studying")} ${
                                  edu.department
                                } ${t("at")} ${edu.university}`
                              : `${t("Graduated after 4 years of studying")} ${
                                  edu.department
                                } at ${edu.university}`}
                          </span>
                        </div>
                      ))}
                    </>
                  ) : (
                    ""
                  )}
                  <p
                    style={{
                      fontSize: "10px",
                      fontWeight: "bolder",
                      color: inputFieldsPalette.current.second,
                    }}
                    className="text-center mt-1 p-1 rounded mr-2 bold"
                  >
                    EDUCATION HISTORY
                  </p>

                  {inputFieldsJob.current.map((job, index) => (
                    <div key={index} className="mt-1">
                      <div className="flex l items-start">
                        <p
                          className="text-left text-gray-700"
                          style={{ fontSize: "10px" }}
                        >
                          Worked as a{" "}
                          <span
                            className="uppercase"
                            style={{ fontWeight: "bold" }}
                          >
                            {job.position}
                          </span>
                          <span>
                            {" "}
                            {t("at")} {job.place}
                          </span>
                          <span>
                            {" "}
                            {t("since")} {job.year2}
                          </span>
                        </p>
                      </div>
                    </div>
                  ))}
                  <p
                    style={{
                      fontSize: "10px",
                      fontWeight: "bolder",
                      color: inputFieldsPalette.current.second,
                    }}
                    className="text-center mt-3  p-1 rounded mr-2 bold"
                  >
                    {t("SOCIAL LINKS")}
                  </p>
                  <div className="link-container">
                    {user ? (
                      <div className="qr-container">
                        <Qr
                          url2={
                            "https://linkakam.com/" + user.username.toString()
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default GetCv;
