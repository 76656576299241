import React, { useState } from "react";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import axios from "axios";
import { useTranslation } from "react-i18next";

async function loginUser(credentials, islog) {
  if (!islog) {
    return fetch("https://api.linkakam.com/api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    })
      .then((data) => data.json())
      .catch((data) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: data.error_message,
        });
      });
  }
}

const Login = ({ setToken }) => {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [email, setemail] = useState();
  const [conpassword, setconpassword] = useState();
  const [islog, setislog] = useState(false);
  const [isreg, setisreg] = useState(true);
  const [isforget, setisforget] = useState(!true);
  const [errorMessage, setErrorMessage] = useState('');

  const handlelogin = async (e) => {
    e.preventDefault();
    const token = await loginUser({
      username,
      password,
    });
    if (token?.user || false) {
      setToken(token);

      window.location.reload();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: token.error_message,
      });
    }
  };
  function getToken() {
    const tokenString = localStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    return userToken?.token;
  }


  const handleforget = async (e) => {
    e.preventDefault();
    axios
      .post("https://api.linkakam.com/api/forget", {
        email,
      })
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          title: "The link was send to your email",
          text: "check your email",
        });
      })
      .catch((data) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Email not found",
        });
      });
  };

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    if (value.includes(' ')) {
      setErrorMessage(t('Username cannot contain spaces'));
    } else {
      setErrorMessage('');
    }
    setUserName(value);
  };

   const handlereg = async (e) => {
    e.preventDefault();

    // Check for errors before proceeding
    if (errorMessage) {
      Swal.fire({
        icon: "error",
        title: "Invalid Input",
        text: errorMessage,
      });
      return;
    }

    // Check if password and confirm password match
    if (password !== conpassword) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Passwords do not match",
      });
      return;
    }

    // Proceed with the form submission
    try {
      const response = await axios.post("https://api.linkakam.com/api/register", {
        username,
        password,
        email,
      });

      // Assuming you want to store the token and reload
      setToken(response.data);  // Assuming setToken is defined
      window.location.reload();
    } catch (error) {
      let errorMessage = '';
      if (error.response && error.response.data && error.response.data.errors) {
        let errors = Object.values(error.response.data.errors);
        errorMessage = errors.join(' ');
      }
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: errorMessage || 'An error occurred',
      });
    }
  };
  const { t } = useTranslation();

  return (
    <div>
      <div className="logcon">

        <img
          alt=""
          src={`${process.env.PUBLIC_URL}/images/11.png`}
          className="absolute  md:w-6/12 object-cover  bottom-0 left-0 -z-10 w-full   md:block"
        />
        <div className="allcon">
          <div className="btncontainar">
            <button
              style={{
                backgroundColor: islog ? "#2a5ce6" : "#f4f4f4",
                color: islog ? "white" : "black",
              }}
              onClick={() => {
                setislog(true);
                setisreg(false);
                setisforget(!true);
              }}
            >
              <h2>{t("register")}</h2>
            </button>
            <button
              style={{
                backgroundColor: !islog ? "#2a5ce6" : "#f4f4f4",
                color: !islog ? "white" : "black",
              }}
              onClick={() => {
                setisreg(true);
                setislog(false);
                setisforget(!true);
              }}
            >
              {t("login")}
            </button>
          </div>

          <div
            className="login-wrapper"
            style={{ display: islog ? "none" : "flex" }}
          >
            <h1 style={{ fontSize: 22 }}> {t("Please Log In")} </h1>
            <form className="loginform" onSubmit={handlelogin}>
              <label>
                <input
                  required
                  type="text"
                  placeholder={t("User Name")}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </label>
              <label>
                <input
                  type="password"
                  required
                  placeholder={t("password")}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </label>
              <div>
                <button type="submit"> {t("Submit")}</button>
              </div>
            </form>
            <button
              onClick={() => {
                setisforget(!true);
                setislog(!false);
                setisreg(!true);
              }}
            >
              {t("Don't have an account?")}
            </button>
            <button
              onClick={() => {
                setislog(!false);
                setisforget(true);
                setisreg(!false);
              }}
            >
              {t("forget password")}
            </button>
          </div>

          <div
            className="login-wrapper"
            style={{ display: isreg ? "none" : "flex" }}
          >
            <h1 style={{ fontSize: 22 }}> {t("Register Please")}</h1>
            <form className="loginform" onSubmit={handlereg}>
              <label>
                <input
                  placeholder="username"
                  required
                  type="text"
                  onChange={handleUsernameChange}
                  value={username}
                />
              </label>
              <label>
                <input
                  placeholder={t("email")}
                  required
                  type="email"
                  onChange={(e) => setemail(e.target.value)}
                />
              </label>
              <label>
                <input
                  placeholder={t("Password")}
                  type="password"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
              </label>
              <label>
                <input
                  placeholder={t("Password")}
                  type="password"
                  required
                  onChange={(e) => setconpassword(e.target.value)}
                />
              </label>
              <div>
                <button type="submit"> {t("Submit")}</button>
              </div>
            </form>
            <button
              onClick={() => {
                setislog(false);
                setisreg(true);
              }}
            >
              {t("Already have an account?")}
            </button>
          </div>

          <div
            className="login-wrapper"
            style={{ display: !isforget ? "none" : "flex" }}
          >
            <h1 style={{ fontSize: 14 }}>
              {t("enter your email and u will recive reset password link")}
            </h1>
            <form className="loginform" onSubmit={handleforget}>
              <label>
                <input
                  placeholder={t("email")}
                  required
                  type="email"
                  onChange={(e) => setemail(e.target.value)}
                />
              </label>

              <div>
                <button type="submit">{t("Submit")} </button>
              </div>

            </form>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

          </div>
        </div>
      </div>
    </div>
  );
};
Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
export default Login;
