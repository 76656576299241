import { useTranslation } from "react-i18next";
import React from "react";
import "../style/cv.css";


const openGetCv = () => {
  window.location.href = '/en/cv/getCv';};
const openHire = () => {
  window.location.href = '/en/cv/hire';
};
function Cv() {
  const { t } = useTranslation();
  return (
    <div>
      <div class="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500  flex  flex-col h-screen justify-center items-center  ">
      <h1 className="text-6xl  title font-bold text-white mb-14">{t("Linkakam job finder")}</h1>
        <div> 
          <button className="gradient-button text-2xl  py-6  rounded-lg shadow-lg hover:bg-gray-200"
          onClick={openHire}
          >
          {t("Hire")}
        </button>
        <button className="gradient-button text-2xl  py-6  rounded-lg shadow-lg hover:bg-gray-200"
         onClick={openGetCv}
        >
          {t("Get cv")}
        </button>
        </div>

        <div> 
    
        </div>
      </div>
    </div>
  );
}


export default Cv;
