import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import axios from "../axios";
import Qr from "../componant/QrCv";
import Modal from 'react-modal';
import "../style/hire.css";
import ReactLoading from "react-loading";

function Hire() {
    const { t } = useTranslation();

    const [cvData, setCvData] = useState([]);
    const [allCvData, setAllCvData] = useState([]);
    const [search, setSearch] = useState("");
    const [imgchange, setimgchange] = useState(false);
    const [picture, setpicture] = useState(" ");
    const [havepic, sethavepic] = useState(false);
    const [showpicture, setshowpicture] = useState();
    const [imgSet, setimgSet] = useState(false);
    const [temp, settemp] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const inputFieldsSkill = useRef([{ singleSkill: "" }]);
    const inputFieldsPalette = useRef([{ first: "", second: "", third: "" }]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [getId, setId] = useState("");
    const [user, setUser] = useState(null);
    const [load, setload] = useState(false);
    const [image, setImage] = useState(" ");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5); // You can change this number as needed
    const [latestCvData, setLatestCvData] = useState([]);
    const [display, setDisplay] = useState(true);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = cvData.slice(indexOfFirstItem, indexOfLastItem);
    const [mobileData, setMobileData] = useState([]);

    function ProgressBar({ percentage }) {
        const validPercentage = percentage ? percentage : '0%';
        return (
            <div className="w-full bg-gray-200 rounded-full h-2 dark:bg-gray-800">
                <div className="h-2 rounded-full" style={{ width: validPercentage, backgroundColor: inputFieldsPalette.current.third }}></div>
            </div>
        );
    }

    const customStyles = {
        content: {
            borderRadius: 20,
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
        },
    };



    function afterOpenModal() { }

    function openModal() {
        setIsOpen(true);

    }

    function closeModal() {
        setIsOpen(false);
    }
    // Initial data fetch

    const inputFieldsContact = useRef({
        phone: "",
        email: "",
    });

    // PERSONAL INFO
    const inputFieldsInfoRef = useRef({
        name: "",
        experties: "",
        address: "",
        birthdate: "",
        about: "",
        image: "",
    });

    const inputFieldsEdu = useRef([
        { university: "", yearEdu: "", status: "", degree: "" },
    ]);

    //LINK
    const links = useRef([
        { social_name: "", icon: "", link: "" },
    ]);

    //jOB
    const inputFieldsJob = useRef([
        { position: "", place: "", year2: "" },
    ]);
    const inputFieldsLang = useRef([{ language: "", level: "" }]);

    const showCv = (id) => {
        setRefresh((prev) => !prev); // Trigger a re-render
        setload(true);

        axios
            .get(`https://api.linkakam.com/api/cv/${id}`)
            .then((response) => {
                const cvData = response.data.cv;
                setImage(cvData.image);
                setUser(response.data.user);
                // Parse the 'info' field of cvData
                const infoObj = JSON.parse(cvData.info);
                console.log("Parsed Info Object:", infoObj);

                // Update inputFieldsInfoRef with the parsed data
                inputFieldsInfoRef.current = {
                    name: infoObj.name,
                    experties: infoObj.experties,
                    address: infoObj.address,
                    birthdate: infoObj.birthdate,
                    about: infoObj.about,
                    image: infoObj.image,
                };
                const imageObj = (cvData.image);
                if (imageObj !== null) {
                    console.log("its null");
                    sethavepic(true);
                    setimgchange(true);
                }
                console.log("Parsed Info Object:", imageObj);
                setpicture(imageObj);

                const newAryLink = response.data.links.map((element) => {
                    return {
                        social_name: element.social_name,
                        icon: element.icon,
                        link: element.link,
                    };
                });
                const contactObj = JSON.parse(cvData.contact);
                console.log("Parsed contact Object:", contactObj);

                // Update inputFieldsContact with the parsed contact information
                inputFieldsContact.current = {
                    phone: contactObj.current.phone,
                    email: contactObj.current.email,
                    website: contactObj.current.website || "", // Add website if you need it
                };



                if (newAryLink.length > 0) {
                    links.current = newAryLink;
                }
                if (response.data.cv) {
                    const cvData = response.data.cv;

                    // Assuming each of these fields is a JSON string in your cvData
                    const languageData = JSON.parse(cvData.language);
                    inputFieldsLang.current = languageData;

                    const skillData = JSON.parse(cvData.skills);
                    inputFieldsSkill.current = skillData;

                    const eduData = JSON.parse(cvData.education);
                    inputFieldsEdu.current = eduData;

                    const jobData = JSON.parse(cvData.work);
                    inputFieldsJob.current = jobData;

                    setRefresh((prev) => !prev); // Trigger a re-render
                }

                let paletteObj;
                try {
                    paletteObj = JSON.parse(cvData.palette);
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                    paletteObj = { first: '#defaultFirstColor', second: '#defaultSecondColor', third: '#defaultThirdColor' };
                }

                // Update inputFieldsPalette with the parsed palette information
                inputFieldsPalette.current = {
                    first: paletteObj.first || '#defaultFirstColor',
                    second: paletteObj.second || '#defaultSecondColor',
                    third: paletteObj.third || '#defaultThirdColor',
                };
                setload(false);

            }).catch(error => {
                console.error('Error fetching CV data:', error);
                // Handle the error appropriately
                setload(false);

            });

        openModal();

    }
    useEffect(() => {
        Modal.setAppElement('#root');
        axios.get("https://api.linkakam.com/api/cv/cvs")
            .then((response) => {
                if (response.data.cvs && response.data.cvs.length > 0) {
                    const data = response.data.cvs.map(cv => cv);

                    // Separate the latest 5 CVs and the rest
                    setLatestCvData(data.slice(0, 5));
                    setCvData(data.slice(5)); // This sets the remaining CVs
                    setAllCvData(data.slice(5));
                    setMobileData(data);
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });

    }, []);

    function formatTextWithLineBreaks(text, maxChars) {
        const result = [];
        for (let i = 0; i < text.length; i += maxChars) {
            result.push(text.substring(i, Math.min(i + maxChars, text.length)));
        }
        return result;
    }

    const searchCv = async () => {
        if (!search) {
            resetSearch();
            return;
        }
        try {
            const response = await axios.get(`https://api.linkakam.com/api/cv/search/${search}`);
            if (response.data.cv && Array.isArray(response.data.cv)) {
                const cvData = response.data.cv.map(cv => cv);
                setCvData(cvData);
                setMobileData(cvData);

                setDisplay(false);
            }
        } catch (error) {
            console.error("Error during search:", error);
            // Handle error appropriately
        }
    };

    const resetSearch = () => {
        setSearch("");
        setDisplay(true);
        setCvData(allCvData);
    };

    function formatTextWithLineBreaks(text, maxChars) {
        const result = [];
        for (let i = 0; i < text.length; i += maxChars) {
            result.push(text.substring(i, Math.min(i + maxChars, text.length)));
        }
        return result;
    }
    const aboutText = inputFieldsInfoRef.current.about;
    const formattedAbout = formatTextWithLineBreaks(aboutText, 32);

    return (
        <>
            <div className="back bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 flex flex-col h-full min-h-screen pt-8  items-center flex-wrap">
                <h1 className="text-white text-2xl">{t("Search for a suitable employee?")}</h1>
                <div className="border bg-white bg-opacity-90 border-gray-500 rounded-full overflow-hidden h-10 mt-4 flex justify-between items-center">
                    <input
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value);
                            if (e.target.value === "") {
                                resetSearch(); // If input gets empty, reset the search
                            }
                        }}
                        placeholder={t("Search For Employee")}
                        type="text"
                        className="focus:outline-none  py-2 bg-transparent pl-8"
                    />
                    <button
                        onClick={() => searchCv()}
                        className="text-sm gradient-button2 py-2 px-4  h-full  rounded-full text-white  basis-4/12"
                    >
                        {t("Search")}
                    </button>

                </div>
                {display &&
                    <>
                        <h1 className="mt-4 text-3xl text- text-white">Five Newest CV</h1>
                        <div className="md:grid hidden grid-cols-5 align-middle mt-4 gap-5 z-0">
                            {latestCvData.map((data, index) => (
                                JSON.parse(data.info).state == false ?
                                    <div key={index}>
                                        <div class="w-52 h-60 shadow-lg relative hover:scale-105 hover:shadow-xl">
                                            {
                                                data.image ? (
                                                    <div class="h-full relative">
                                                        <img class="object-cover h-full w-full" alt="linkakam" src={"https://api.linkakam.com/cv/" + data.image} />
                                                        <div class="absolute inset-0 bg-black bg-opacity-50"></div>
                                                        <div class="absolute bottom-0 left-0 right-0 p-6 z-10">
                                                            <h5 class="text-xl font-bold text-white">{JSON.parse(data.info).name ? JSON.parse(data.info).name : 'None'}</h5>
                                                            <h5 class="text-sm font-bold text-white">{JSON.parse(data.info).experties ? JSON.parse(data.info).experties : 'None'}</h5>
                                                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 mt-1  ">{JSON.parse(data.info).employmentType ? JSON.parse(data.info).employmentType : 'None'}</p>
                                                            <div className="">
                                                                <button onClick={() => { showCv(JSON.parse(data.info).id) }} class=" inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                                    Show CV
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (

                                                    <div class="h-full relative">
                                                        <img class="object-cover h-full w-full" alt="linkakam" src={`${process.env.PUBLIC_URL}/images/pp.png`} />
                                                        <div class="absolute inset-0 bg-black bg-opacity-50"></div>
                                                        <div class="absolute bottom-0 left-0 right-0 p-6 z-10">
                                                            <h5 class="text-xl font-bold text-white">{JSON.parse(data.info).name ? JSON.parse(data.info).name : 'None'}</h5>
                                                            <h5 class="text-sm font-bold text-white">{JSON.parse(data.info).experties ? JSON.parse(data.info).experties : 'None'}</h5>
                                                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 mt-1  ">{JSON.parse(data.info).employmentType ? JSON.parse(data.info).employmentType : 'None'}</p>
                                                            <div className="">
                                                                <button onClick={() => { showCv(JSON.parse(data.info).id) }} class=" inline-flex items-center z-0  px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                                    Show CV
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div> : ''
                            ))}
                        </div>
                    </>
                }
                <div className="md:grid hidden  grid-cols-5 align-middle mt-24 gap-5">
                    {
                        cvData.map((data, index) => (
                            JSON.parse(data.info).state == false ?
                                <div key={index}>
                                    <div class="w-52 h-60  shadow-lg relative hover:scale-105 hover:shadow-xl">

                                        {
                                            data.image ? (
                                                <div class="h-full relative">
                                                    <img class="object-cover h-full w-full" alt="linkakam" src={"https://api.linkakam.com/cv/" + data.image} />
                                                    <div class="absolute inset-0 bg-black bg-opacity-50"></div>
                                                    <div class="absolute bottom-0 left-0 right-0 p-6 z-10">
                                                        <h5 class="text-xl font-bold text-white">{JSON.parse(data.info).name ? JSON.parse(data.info).name : 'None'}</h5>
                                                        <h5 class="text-sm font-bold text-white">{JSON.parse(data.info).experties ? JSON.parse(data.info).experties : 'None'}</h5>
                                                        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 mt-1  ">{JSON.parse(data.info).employmentType ? JSON.parse(data.info).employmentType : 'None'}</p>
                                                        <div className="">
                                                            <button onClick={() => { showCv(JSON.parse(data.info).id) }} class=" inline-flex items-center z-0 px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                                Show CV
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (

                                                <div class="h-full relative">
                                                    <img class="object-cover h-full w-full" alt="linkakam" src={`${process.env.PUBLIC_URL}/images/pp.png`} />
                                                    <div class="absolute inset-0 bg-black bg-opacity-50"></div>
                                                    <div class="absolute bottom-0 left-0 right-0 p-6 z-10">
                                                        <h5 class="text-xl font-bold text-white">{JSON.parse(data.info).name ? JSON.parse(data.info).name : 'None'}</h5>
                                                        <h5 class="text-sm font-bold text-white">{JSON.parse(data.info).experties ? JSON.parse(data.info).experties : 'None'}</h5>
                                                        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 mt-1  ">{JSON.parse(data.info).employmentType ? JSON.parse(data.info).employmentType : 'None'}</p>
                                                        <div className="">
                                                            <button onClick={() => { showCv(JSON.parse(data.info).id) }} class=" inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                                Show CV
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }

                                    </div>
                                </div> : ''
                        ))
                    }
                </div>
                <div className="pagination mt-3 mb-4">
                    {Array.from({ length: Math.ceil(cvData.length / itemsPerPage) }, (_, i) => i + 1).map(pageNumber => (
                        <button
                            key={pageNumber}
                            onClick={() => setCurrentPage(pageNumber)}
                            className={`pagination-button ${currentPage === pageNumber ? 'active' : ''}`}
                        >
                            {pageNumber}
                        </button>
                    ))}
                </div>
                {/* Mobile */}
                <div className="grid grid-cols-2 align-middle mt-6 gap-4 md:hidden">
                    {
                        mobileData.map((data, index) => (
                            JSON.parse(data.info).state == false ?
                                <div key={index}>
                                    <div class="w-48 h-52 p-2 shadow-lg relative hover:scale-105 hover:shadow-xl">

                                        {
                                            data.image ? (
                                                <div class="h-full relative">
                                                    <img class="object-cover h-full w-full" alt="linkakam" src={"https://api.linkakam.com/cv/" + data.image} />
                                                    <div class="absolute inset-0 bg-black bg-opacity-50"></div>
                                                    <div class="absolute bottom-0 left-0 right-0 p-6 z-10">
                                                        <h5 class="text-xl font-bold text-white">{JSON.parse(data.info).name ? JSON.parse(data.info).name : 'None'}</h5>
                                                        <h5 class="text-sm font-bold text-white">{JSON.parse(data.info).experties ? JSON.parse(data.info).experties : 'None'}</h5>
                                                        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 mt-1  ">{JSON.parse(data.info).employmentType ? JSON.parse(data.info).employmentType : 'None'}</p>
                                                        <div className="">
                                                            <button onClick={() => { showCv(JSON.parse(data.info).id) }} class=" inline-flex items-center z-0 px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                                Show CV
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div class="h-full relative">
                                                    <img class="object-cover h-full w-full" alt="linkakam" src={`${process.env.PUBLIC_URL}/images/pp.png`} />
                                                    <div class="absolute inset-0 bg-black bg-opacity-50"></div>
                                                    <div class="absolute bottom-0 left-0 right-0 p-6 z-10">
                                                        <h5 class="text-xl font-bold text-white">{JSON.parse(data.info).name ? JSON.parse(data.info).name : 'None'}</h5>
                                                        <h5 class="text-sm font-bold text-white">{JSON.parse(data.info).experties ? JSON.parse(data.info).experties : 'None'}</h5>
                                                        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 mt-1  ">{JSON.parse(data.info).employmentType ? JSON.parse(data.info).employmentType : 'None'}</p>
                                                        <div className="">
                                                            <button onClick={() => { showCv(JSON.parse(data.info).id) }} class=" inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                                Show CV
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }

                                    </div>
                                </div> : ''
                        ))
                    }
                </div>
                {/* Preview */}
                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                    appElement={document.getElementById('root')}
                >
                    {load ? (
                        <div style={{ margin: "auto" }}>
                            <ReactLoading
                                type={"spin"}
                                color={"#2a5ce6"}
                                height={60}
                                width={60}
                            />
                        </div>
                    ) : <>
                        <div className="text-right">
                            <button
                                className=" bg-red-700 "
                                style={{
                                    width: 30,
                                    height: 30,
                                    borderRadius: 20,
                                    color: "white",
                                }}
                                onClick={closeModal}
                            >
                                {t("X")}
                            </button>

                        </div>

                        <div className=" mr-2 rounded-lg bg-gray-50 p-4 w-[397px]">
                            <div className="flex flex-row">
                                <div className="col-md-6 rounded-lg p-6 mt-2 " style={{ backgroundColor: inputFieldsPalette.current.first }}>
                                    <div className="p-2 rounded-lg al">
                                        <label className="">
                                            <>
                                                {

                                                    image ? (
                                                        <img
                                                            className=" z-50 "
                                                            alt="linkakam"
                                                            src={"https://api.linkakam.com/cv/" + image}
                                                            style={{
                                                                borderTopLeftRadius: "50% 50%",
                                                                borderTopRightRadius: "50% 50%",
                                                                borderBottomRightRadius: "50% 50%",
                                                                borderBottomLeftRadius: "50% 50%",
                                                                width: "55px",
                                                                boxShadow: "0 0 5px rgb(129, 129, 129)",
                                                                border: "3px solid",
                                                                borderColor: inputFieldsPalette?.current.second,
                                                                aspectRatio: "1 / 1",
                                                                objectFit: "cover",
                                                                margin: "0 auto"
                                                            }}
                                                        />
                                                    ) : (
                                                        <img
                                                            style={{
                                                                borderTopLeftRadius: "50% 50%",
                                                                borderTopRightRadius: "50% 50%",
                                                                borderBottomRightRadius: "50% 50%",
                                                                borderBottomLeftRadius: "50% 50%",
                                                                width: "55px",
                                                                boxShadow: "0 0 5px rgb(129, 129, 129)",
                                                                border: "3px solid",
                                                                borderColor: inputFieldsPalette?.current.second,
                                                                aspectRatio: "1 / 1",
                                                                objectFit: "cover",
                                                                margin: "0 auto"
                                                            }}
                                                            alt="linkakam"

                                                            src={`${process.env.PUBLIC_URL}/images/pp.png`}
                                                        />
                                                    )
                                                }
                                            </>
                                        </label>
                                    </div>
                                    <div className=" p-2">
                                        <p style={{ fontWeight: "bolder", fontSize: 12 }} className="text-center  text-white uppercase ">{inputFieldsInfoRef.current.name}</p>
                                        <p style={{
                                            fontSize: 9,
                                            fontWeight: "thin", color: inputFieldsPalette?.current.second // This sets the text color
                                        }} className="text-center   capitalize ">{inputFieldsInfoRef.current.experties}</p>
                                    </div>
                                    <div class="flex w-full">
                                        <div class="flex-1 h-full " style={{}}>
                                            <div className="flex items-center h-3 mt-2 -ml-2" style={{ borderLeft: "22px solid white" }}>
                                                <p style={{ fontSize: "10px", fontWeight: "bolder" }} className="text-left mb-1  text-white p-1  rounded mr-2 bold ">{t('CONTACT')}</p></div>
                                            <p style={{ fontSize: "10px" }} className="text-left text-white mt-2  capitalize flex"><svg xmlns="http://www.w3.org/2000/svg"
                                                width="12" height="12"
                                                fill={inputFieldsPalette?.current?.second} // Set the fill attribute directly
                                                style={{ color: inputFieldsPalette?.current.second }} className="mr-1 bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                                            </svg>{inputFieldsInfoRef.current.address}</p>

                                            <p style={{ fontSize: "10px" }} className="text-left text-white  capitalize mt-1 flex"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill={inputFieldsPalette?.current?.second} // Set the fill attribute directly
                                                style={{ color: inputFieldsPalette?.current.second }} class="mr-1 bi bi-telephone-fill" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                            </svg>{inputFieldsContact.current.phone}</p>

                                            <p style={{ fontSize: "10px" }} className="text-left text-white  capitalize mt-1 flex"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill={inputFieldsPalette?.current?.second} // Set the fill attribute directly
                                                style={{ color: inputFieldsPalette?.current.second }} class="mr-1 bi bi-envelope-at-fill" viewBox="0 0 16 16">
                                                <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2zm-2 9.8V4.698l5.803 3.546L0 11.801Zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 9.671V4.697l-5.803 3.546.338.208A4.482 4.482 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671" />
                                                <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791Z" />
                                            </svg>{inputFieldsContact.current.email}</p>

                                            <div className="">
                                                <div className="flex items-center h-3 mt-2 -ml-2" style={{ borderLeft: "22px solid white" }}>
                                                    <p style={{ fontSize: "10px", fontWeight: "bolder", }} className="text-left mb-1 mt-1  text-white p-1 uppercase  rounded mr-2 bold ">{t('Skill set')}</p></div>
                                                <div className="mt-2">
                                                    {inputFieldsSkill.current.map((singleSkill, index) => (
                                                        <div key={index} className="flex items-center">
                                                            <p style={{ fontSize: "10px" }} className="text-left  text-white  capitalize flex">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="5"
                                                                    height="5"
                                                                    fill={inputFieldsPalette?.current?.second} // Set the fill attribute directly
                                                                    className="mt-1 mr-1 bi bi-circle-fill"
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <circle cx="8" cy="8" r="8" />
                                                                </svg>
                                                                {singleSkill.singleSkill}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            {/* language */}
                                            {inputFieldsLang.current.length > 0 && (
                                                <div className="">
                                                    <div className="flex items-center h-3 mt-2 -ml-2" style={{ borderLeft: "22px solid white" }}>
                                                        <p style={{ fontSize: "10px", fontWeight: "bolder" }} className="text-left mb-1 mt-1 text-white p-1 uppercase rounded mr-2 bold ">
                                                            {t('Language')}
                                                        </p>
                                                    </div>
                                                    <div className="mt-2">
                                                        {inputFieldsLang.current.map((field, index) => (
                                                            <div key={index} className="flex flex-col mb-2">
                                                                <div className="flex items-center">
                                                                    <p style={{ fontSize: "10px" }} className="text-left text-white capitalize flex">
                                                                        {field.lang}
                                                                    </p>
                                                                </div>
                                                                {field.level && <ProgressBar percentage={`${field.level}`} />}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 rounded-lg bg-gray-50 w-[397px] h-[561.5px]">
                                    <div class="flex-1 h-full ml-2" >

                                        {formattedAbout.length > 0 && (
                                            <>
                                                <div className="-mt-2" style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <p
                                                        style={{ fontSize: "10px", fontWeight: "bolder", backgroundColor: inputFieldsPalette.current.second }}
                                                        className="text-center uppercase mt-2 text-white mb-2 w-1/2 px-1 py-4 bold">
                                                        {t('About me')}
                                                    </p>
                                                </div>
                                                <p style={{ fontSize: "10px" }} className="text-center capitalize flex">
                                                    {formattedAbout.map((line, index) => (
                                                        <React.Fragment key={index}>
                                                            {line}
                                                            <br />
                                                        </React.Fragment>
                                                    ))}
                                                </p>
                                            </>
                                        )}

                                        {inputFieldsEdu?.current.length > 0 && (
                                            <>
                                                <p
                                                    style={{ fontSize: "10px", fontWeight: "bolder", color: inputFieldsPalette.current.second }}
                                                    className="text-center mt-2 p-1 rounded mr-2 bold">
                                                    {t('EDUCATION HISTORY')}
                                                </p>

                                                {inputFieldsEdu.current.map((edu, index) => (
                                                    <div key={index} className="mt-2">
                                                        <div
                                                            className="flex flex-col items-start"
                                                            style={{ paddingLeft: "2px", borderLeft: "solid 12px", borderColor: inputFieldsPalette.current.second }}>
                                                            <p className="text-left uppercase text-gray-700 text-smaller-spacing font-bold">
                                                                {edu.department}
                                                            </p>
                                                        </div>
                                                        {edu ?
                                                            <span className="text-left text-smaller-spacing">
                                                                {edu.degree === 0 ? `Diploma degree | ${edu.yearEdu}` : `Bachelor degree | ${edu.yearEdu}`}
                                                            </span> : ""}

                                                        <span className="text-left text-smaller-spacing">
                                                            {edu.degree === 0 ? `${t("Graduated after 2 years of studying")} ${edu.department} ${t("at")} ${edu.university}` : `${t("Graduated after 4 years of studying")} ${edu.department} at ${edu.university}`}
                                                        </span>
                                                    </div>
                                                ))}

                                                <p
                                                    style={{ fontSize: "10px", fontWeight: "bolder", color: inputFieldsPalette.current.second }}
                                                    className="text-center mt-1 p-1 rounded mr-2 bold">
                                                    {t('WORK EXPERIENCE')}

                                                </p>

                                                {inputFieldsJob.current.map((job, index) => (
                                                    <div key={index} className="mt-1">
                                                        <div className="flex l items-start">
                                                            <p className="text-left text-gray-700" style={{ fontSize: "10px" }}>
                                                                Worked as a <span className="uppercase" style={{ fontWeight: "bold" }}>{job.position}</span>
                                                                <span> {t('at')} {job.place}</span>
                                                                <span> {t('since')} {job.year2}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        )}

                                        <p style={{ fontSize: "10px", fontWeight: "bolder", color: inputFieldsPalette.current.second }} className="text-center mt-3  p-1 rounded mr-2 bold" >
                                            {t('SOCIAL LINKS')}
                                        </p>
                                        <div className="link-container">
                                            {user ? (
                                                <div className="qr-container">
                                                    <Qr url2={"https://linkakam.com/" + user.username.toString()} />
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div></>}
                </Modal>
            </div>
        </>
    )
}

export default Hire;
