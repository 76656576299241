import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../style/login.css";
import axios from "../axios";
import "../style/Navbar.css";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const { t } = useTranslation();
  const location = useLocation().pathname;
  let auth = JSON.parse(localStorage.getItem("token"));
  const logout = () => {
    axios
      .post("/logout")
      .then(() => {
        localStorage.removeItem("token");
        window.location.href = "/en/home";
      })
      .catch(() => {
        localStorage.removeItem("token");
        window.location.reload();
      });
  };
  const [nav, setNav] = useState(false);
  const [lang, setLang] = useState(
    localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
  );
  useEffect(() => {
    setNav(false);
  }, [location]);
  return (
    <div>
      <div className=" bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600  shadow-2xl   w-full md:w-full px-4 md:px-32 p-2 ">
        <div className=" md:flex items-center   justify-between  w-full md:w-full  ">
          <div className="space-x-5 text-gray-500 flex items-center justify-between   ">
            <Link to="/en/home" className="text-[#2a5ce6] text-2xl">
              <img
                src={`${process.env.PUBLIC_URL}/images/zz.png`}
                alt=""
                className=" w-[180px] md:w-36"
              />
            </Link>
            {auth ? (
              <button
                onClick={() => {
                  setNav(!nav);
                }}
              >
                <i className="fas md:hidden fa-bars text-3xl text-white"></i>
              </button>
            ) : (
              <div className="flex flex-row-reverse">
                <div className="space-x-4 md:hidden">
                  <Link
                    to="/en/setup"
                    className="bg-white px-4 py-1 text-blue-600 rounded-full"
                  >
                    {t("Login")}
                  </Link>
                </div>
                <div className="text-black         md:hidden  ">
                  <select
                    onChange={(value) => {
                      let lng = value.target.value;
                      localStorage.setItem("lang", lng);
                      setLang(lng);
                      i18n.changeLanguage(lng);
                      window.location.reload();
                      if (lng === "en") {
                        document
                          .getElementById("style")
                          .setAttribute(
                            "href",
                            "/assets/css/darkstyle" + lng + ".css"
                          );
                      } else {
                        document
                          .getElementById("style")
                          .setAttribute("href", "/assets/css/darkstyle.css");
                      }
                    }}
                    className="text-[sm] md:lg text-white   bg-transparent border-none focus:outline-none "
                    style={{
                      border: "none",
                      width: "40px",
                      background: "#0d1120 !important",
                    }}
                    id=""
                  >
                    <option
                      value="en"
                      selected={lang === "en" || lang == "" ? true : false}
                      className="text-dark text-black"
                    >
                      En
                    </option>
                    <option
                      value="ar"
                      selected={lang === "ar" ? true : false}
                      className="text-dark text-black"
                    >
                      Ar
                    </option>

                    <option
                      value="ku"
                      selected={lang === "ku" ? true : false}
                      className="text-dark text-black"
                    >
                      Ku
                    </option>
                  </select>
                </div>
              </div>
            )}
            {auth ? (
              <div className=" hidden md:flex pt-2 space-x-4    ">
                <Link
                  className={
                    (location === "/en/home"
                      ? "text-white font-bold"
                      : "text-gray-300  ") + " text-lg mx-4"
                  }
                  to="/en/home"
                >
                  {t("Home")}
                </Link>
                <Link
                  className={
                    (location === "/en/show"
                      ? "text-white font-bold"
                      : "text-gray-300  ") + " text-lg mx-4"
                  }
                  to="/en/show"
                >
                  <p>{t("Show")} </p>
                </Link>
                <Link
                  className={
                    (location === "/en/setup"
                      ? "text-white font-bold"
                      : "text-gray-300  ") + " text-lg mx-4"
                  }
                  to="/en/setup"
                >
                  <p>{t("Setup")} </p>
                </Link>
                <Link
                  className={
                    (location === "/en/Cv"
                      ? "text-white font-bold"
                      : "text-gray-300  ") + " text-lg mx-4"
                  }
                  to="/en/Cv"
                >
                  <p>{t("Cv")} </p>
                </Link>
                <Link
                  className={
                    (location === "/en/Resturants"
                      ? "text-white font-bold"
                      : "text-gray-300  ") + " text-lg mx-4"
                  }
                  to="/en/Resturants"
                >
                  <p>{t("Resturants")} </p>
                </Link>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div>
            {/* laptop */}
            {auth ? (
              <div className="w-60   flex  md: flex-row-reverse md:justify-between items-center">
                <div className=" hidden md:block">
                  <button
                    className="px-1 py-1 rounded-full"
                    onClick={() => {
                      logout();
                    }}
                  >
                    <i className="fas fa-sign-out text-white"></i>
                  </button>
                </div>
                <div className="text-black   mx-8 hidden md:block  ">
                  <select
                    onChange={(value) => {
                      let lng = value.target.value;
                      localStorage.setItem("lang", lng);
                      setLang(lng);
                      i18n.changeLanguage(lng);
                      window.location.reload();
                      if (lng === "en") {
                        document
                          .getElementById("style")
                          .setAttribute(
                            "href",
                            "/assets/css/darkstyle" + lng + ".css"
                          );
                      } else {
                        document
                          .getElementById("style")
                          .setAttribute("href", "/assets/css/darkstyle.css");
                      }
                    }}
                    className="text-lg text-white bg-transparent border-none focus:outline-none "
                    style={{
                      border: "none",
                      width: "100px",
                      background: "#0d1120 !important",
                    }}
                    id=""
                  >
                    <option
                      value="en"
                      selected={lang === "en" || lang === "" ? true : false}
                      className="text-dark text-black"
                    >
                      En
                    </option>
                    <option
                      value="ar"
                      selected={lang === "ar" ? true : false}
                      className="text-dark text-black"
                    >
                      Ar
                    </option>

                    <option
                      value="ku"
                      selected={lang === "ku" ? true : false}
                      className="text-dark text-black"
                    >
                      Ku
                    </option>
                  </select>
                </div>
                <Link className=" hidden md:block" to="/en/setup">
                  <img
                    src={
                      auth?.user?.image
                        ? `https://api.linkakam.com/profile/${auth?.user.image}`
                        : "https://www.ucdenver.edu/images/librariesprovider216/default-album/profile-placeholder.png?sfvrsn=d7e6a7b9_0&Width=300&Height=300&ScaleUp=false&Quality=High&Method=CropCropArguments&Signature=A4AFA3C608912FE30A9CC37E4BC01AE1EEFF0940"
                    }
                    className="w-12  h-12 rounded-full bg-[white] -z-10 object-cover"
                    alt=""
                  />
                </Link>
              </div>
            ) : (
              // loginnabe laptop
              <div className="flex flex-row-reverse ">
                {/* laptop */}
                <div className=" hidden md:block space-x-4">
                  <Link
                    to="/en/setup"
                    className="bg-white px-6 py-1 text-blue-600 rounded-full"
                  >
                    {t("Login")}
                  </Link>
                </div>
                {/* laptop */}
                <div className="text-black  mx-8 hidden md:block  ">
                  <select
                    onChange={(value) => {
                      let lng = value.target.value;
                      localStorage.setItem("lang", lng);
                      setLang(lng);
                      i18n.changeLanguage(lng);
                      window.location.reload();
                      if (lng === "en") {
                        document
                          .getElementById("style")
                          .setAttribute(
                            "href",
                            "/assets/css/darkstyle" + lng + ".css"
                          );
                      } else {
                        document
                          .getElementById("style")
                          .setAttribute("href", "/assets/css/darkstyle.css");
                      }
                    }}
                    className="text-lg text-white bg-transparent border-none focus:outline-none "
                    style={{
                      border: "none",
                      width: "100px",
                      background: "#0d1120 !important",
                    }}
                    id=""
                  >
                    <option
                      value="en"
                      selected={lang === "en" || lang === "" ? true : false}
                      className="text-dark text-black"
                    >
                      En
                    </option>
                    <option
                      value="ar"
                      selected={lang === "ar" ? true : false}
                      className="text-dark text-black"
                    >
                      Ar
                    </option>

                    <option
                      value="ku"
                      selected={lang === "ku" ? true : false}
                      className="text-dark text-black"
                    >
                      Ku
                    </option>
                  </select>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* phone nav*/}
      {nav ? (
        <div
          className="w-full md:hidden h-full absolute top-0 left-0"
          style={{ zIndex: [990] }}
        >
          <div className=" text-4xl p-12 h-full fixed w-full back bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500  min-h-screen  items-center flex-wrap">
            <div className="flex justify-end">
              <button
                onClick={() => {
                  setNav(!nav);
                }}
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
            <div className="grid grid-cols-1  gap-10 text-center text-xl pt-2">
              <div className="w-40 md:w-24 flex mx-auto flex-col-reverse md:flex-row-reverse justify-between items-center">
                <div className="flex flex-row-reverse items-center justify-start space-x-4 mt-4">

                  <div className="text-black bg-gray-200 px-1 py-1  rounded-lg w-[150px]">
                    <select
                      onChange={(value) => {
                        let lng = value.target.value;
                        localStorage.setItem("lang", lng);
                        setLang(lng);
                        i18n.changeLanguage(lng);
                        window.location.reload();
                        if (lng === "en") {
                          document
                            .getElementById("style")
                            .setAttribute(
                              "href",
                              "/assets/css/darkstyle" + lng + ".css"
                            );
                        } else {
                          document
                            .getElementById("style")
                            .setAttribute("href", "/assets/css/darkstyle.css");
                        }
                      }}
                      className="text-lg text-black bg-transparent border-none focus:outline-none "
                      style={{
                        border: "none",
                        width: "100px",
                        background: "#0d1120 !important",
                      }}
                      id=""
                    >
                      <option
                        value="en"
                        selected={lang === "en" || lang === "" ? true : false}
                        className="text-dark text-black"
                      >
                        En
                      </option>
                      <option
                        value="ar"
                        selected={lang === "ar" ? true : false}
                        className="text-dark text-black"
                      >
                        Ar
                      </option>

                      <option
                        value="ku"
                        selected={lang === "ku" ? true : false}
                        className="text-dark text-black"
                      >
                        Ku
                      </option>
                    </select>
                  </div>

                  <div>
                    <button className="px-1 py-1" onClick={() => { logout(); }}>
                      <div className="px-2 w-[160px] mr-2  bg-red-800 rounded-lg text-white">
                        {t("logout")}
                        <i className="fas fa-sign-out text-white mx-2 my-3"></i>
                      </div>
                    </button>
                  </div>
                </div>
                <Link to="/en/setup">
                  <img
                    src={
                      auth?.user?.image
                        ? "https://api.linkakam.com/profile/" + auth?.user?.image
                        : `${process.env.PUBLIC_URL}/images/pp.png`
                    }
                    className="w-28 h-28  bg-[white] rounded-full -z-10 object-cover"
                    alt=""
                    style={{ border: "3px solid #fff" }}
                  />
                </Link>
              </div>
              <Link
                className='text-white'
                to="/en/Home"
              >
                {t("Home")}
              </Link>
              <Link
                className='text-white'
                to="/en/show"
              >
                {t("show")}
              </Link>
              <Link
                className='text-white'
                to="/en/setup"
              >
                {t("setup")}
              </Link>
              <Link
                className='text-white'
                to="/en/Cv"
              >
                {t("Cv")}
              </Link>
              <Link
                className='text-white'
                to="/en/Resturants"
              >
                {t("Resturants")}
              </Link>


            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Navbar;
